import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CollectionCardModal from './CollectionCardModal';

function InfoCard(props) {
  const [showModal, setShowModal] = useState(false);

  const getStyle = () => {
    if (!props.preview) {
      return { cursor: "pointer" };
    }
    return {};
  }

  const largeView = () => {
    return <>
      <div className="col-12 col-sm-6 col-md-6 col-lg-4 product-card">
        <Card className='background5 shadow border-0' style={getStyle()} onClick={() => { if (!props.preview) { setShowModal(true) } }}>
          <Card.Title className="product-card-title">{props.card.name}</Card.Title>
          <Card.Text className="product-card-id">{props.card.identifier}</Card.Text>
          <Card.Img variant="top" src={props.card.links.imageStandardQuality} />
          <div className="card-search" style={{ display: 'none' }}>
            <p className="card-search-dna">{props.card.dna}</p>
            <p className="card-search-minted">{(props.card.minted) ? "Minted" : "Not minted"}</p>
            <p className="card-search-name">{props.card.name}</p>
            <p className="card-search-identifier">{props.card.identifier}</p>
            <p className="card-search-species">{props.card.species}</p>
            <p className="card-search-color">{props.card.color}</p>
            <p className="card-search-set">{props.card.collectionSet}</p>
            <p className="card-search-condition">{props.card.condition}</p>
            <p className="card-search-rarity">{props.card.rarity}</p>
          </div>
        </Card >
      </div>
    </>
  }

  const mediumView = () => {
    return <>
      <div className="col-6 col-sm-4 col-lg-3 product-card-medium">
        <Card className='background5 shadow border-0' style={getStyle()} onClick={() => { if (!props.preview) { setShowModal(true) } }}>
          <Card.Title className="product-card-medium-title">{props.card.name}</Card.Title>
          <Card.Text className="product-card-medium-id">{props.card.identifier}</Card.Text>
          <Card.Img variant="top" src={props.card.links.imageLowQuality} />
          <div className="card-search" style={{ display: 'none' }}>
            <p className="card-search-dna">{props.card.dna}</p>
            <p className="card-search-minted">{(props.card.minted) ? "Minted" : "Not minted"}</p>
            <p className="card-search-name">{props.card.name}</p>
            <p className="card-search-identifier">{props.card.identifier}</p>
            <p className="card-search-species">{props.card.species}</p>
            <p className="card-search-color">{props.card.color}</p>
            <p className="card-search-set">{props.card.collectionSet}</p>
            <p className="card-search-condition">{props.card.condition}</p>
            <p className="card-search-rarity">{props.card.rarity}</p>
          </div>
        </Card >
      </div>
    </>
  }

  const smallView = () => {
    return <>
      <div className="col-4 col-sm-3 col-lg-2 col-xl-1 product-card-small">
        <Card className='background5 shadow border-0' style={getStyle()} onClick={() => { if (!props.preview) { setShowModal(true) } }}>
          <Card.Title className="product-card-title-small">{props.card.name}</Card.Title>
          <Card.Text className="product-card-id-small">{props.card.identifier}</Card.Text>
          <Card.Img variant="top" src={props.card.links.imageLowQuality} />
          <div className="card-search" style={{ display: 'none' }}>
            <p className="card-search-dna">{props.card.dna}</p>
            <p className="card-search-minted">{(props.card.minted) ? "Minted" : "Not minted"}</p>
            <p className="card-search-name">{props.card.name}</p>
            <p className="card-search-identifier">{props.card.identifier}</p>
            <p className="card-search-species">{props.card.species}</p>
            <p className="card-search-color">{props.card.color}</p>
            <p className="card-search-set">{props.card.collectionSet}</p>
            <p className="card-search-condition">{props.card.condition}</p>
            <p className="card-search-rarity">{props.card.rarity}</p>
          </div>
        </Card >
      </div>
    </>
  }

  const listView = () => {
    return <>
      <div className="col-4 col-sm-3 col-lg-2 col-xl-1 product-card-list">
        <Card className='background5 shadow border-0' style={getStyle()} onClick={() => { if (!props.preview) { setShowModal(true) } }}>
          <Card.Title className="product-card-title-list">{props.card.name}</Card.Title>
          <Card.Text className="product-card-id-list">{props.card.identifier}</Card.Text>
          <div className="card-search" style={{ display: 'none' }}>
            <p className="card-search-dna">{props.card.dna}</p>
            <p className="card-search-minted">{(props.card.minted) ? "Minted" : "Not minted"}</p>
            <p className="card-search-name">{props.card.name}</p>
            <p className="card-search-identifier">{props.card.identifier}</p>
            <p className="card-search-species">{props.card.species}</p>
            <p className="card-search-color">{props.card.color}</p>
            <p className="card-search-set">{props.card.collectionSet}</p>
            <p className="card-search-condition">{props.card.condition}</p>
            <p className="card-search-rarity">{props.card.rarity}</p>
          </div>
        </Card >
      </div>
    </>
  }

  const getCardView = ({ viewType }) => {
    switch (viewType) {
      case "LIST":
        return listView()

      case "SMALL":
        return smallView()

      case "MEDIUM":
        return mediumView()

      case "LARGE":
      default:
        return largeView()
    }
  }

  return (
    <>
      <CollectionCardModal
        className="card-modal"
        show={showModal}
        showButton={true}
        hide={() => setShowModal(false)}
        card={props.card}
        mintFunction={props.mintFunction} />
      {getCardView({ viewType: props.viewType })}
    </>
  );
}

export default InfoCard;