import React from 'react';
import Modal from 'react-bootstrap/Modal'

function LibraryCardModal(props) {
    return (
        <>
            <Modal
                dialogClassName="card-modal"
                show={props.show}
                onHide={props.hide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><strong className="kaffeesatz">{props.card.name}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-block d-sm-none">
                        <img src={props.card.links.imageStandardQuality} alt="Depicts the selected card in better quality" style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} />
                    </div>
                    <div className="d-none d-sm-block">
                        <img src={props.card.links.imageStandardQuality} alt="Depicts the selected card in better quality" style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LibraryCardModal;
