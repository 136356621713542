import React from 'react';
import Modal from 'react-bootstrap/Modal'

import ConnectIcon from '@mui/icons-material/AccountBalanceWalletRounded';

function ProfileModal(props) {

    const disconnect = () => {
        props.disconnectFunction()
        props.hide()
    }

    function getInfoText() {
        if (props.web3Options) {
            return <>
                { /* Contract info */ }
                <div className="row mt-1">
                    <div className="profile-modal-text-header">
                        <p className="mb-1">Contract</p>
                    </div>
                    <div className="col-4"><strong>Address:</strong></div>
                    <div className="col-8">
                        <a href={`${process.env.REACT_APP_ETHERSCAN_BASE_URL}${props.web3Options.contract}`} rel="noreferrer" target="_blank">{props.web3Options.contract}</a>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-4"><strong>Network:</strong></div>
                    <div className="col-8">{process.env.REACT_APP_NETWORK_NAME}</div>
                </div>
                { /* Wallet info */ }
                <div className="row mt-3">
                    <div className="profile-modal-text-header">
                        <p className="mb-1">Wallet</p>
                    </div>
                    <div className="col-4"><strong>Address:</strong></div>
                    <div className="col-8">{props.web3Options.account}</div>
                    <div className="col-4"><strong>Network:</strong></div>
                    <div className="col-8">{props.web3Options.network}</div>
                </div>
                { /* App info */ }
                <div className="row mt-3">
                    <div className="profile-modal-text-header">
                        <p className="mb-1">Application</p>
                    </div>
                    <div className="col-4"><strong>App version:</strong></div>
                    <div className="col-8">{process.env.REACT_APP_SPA_VERSION}</div>
                </div>

                <div className='d-flex justify-content-center mt-5'>
                
                    <button onClick={disconnect} type="button" className="btn btn-light mx-1 navbar-dark"><ConnectIcon className='align-top' sx={{ fontSize: "24px" }} /><span className='align-top fs-7 ps-1 kaffeesatz'> Disconnect wallet</span></button>
                </div>
            </>
        }
    }

    return (
        <>
            <Modal
                dialogClassName="profile-modal"
                show={props.show}
                onHide={props.hide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><strong className="fs-1">Session</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pt-4 profile-modal-text">
                        {getInfoText()}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ProfileModal;
