import React from 'react';
import Card from 'react-bootstrap/Card';
import BuyPackModal from './BuyPackModal';
import BuyPackBundleModal from './BuyPackBundleModal';
import CountdownTimer from '../../shared/CountdownTimer';
import ClaimPackModal from './ClaimPackModal';

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalFreePack: false,
            showModalPack: false,
            showModalPackBundle: false,
            currentEpoch: Date.now()
        };
        this.interval = undefined;
    }

    /**
     * React hook
     */
    componentDidMount() {
        this.interval = setInterval(this.tick, 1000);
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG"
        } else {
            document.title = "Pawsters NFTCG"
        }
    }

    /**
     * React hook
     */
    componentWillUnmount() {
        clearInterval(this.interval);
    }


    tick = () => {
        this.setState({
            currentEpoch: Date.now()
        });
    }

    setModalFreePack = (boolean) => {
        this.setState({ showModalFreePack: boolean });
    }

    setModalPack = (boolean) => {
        this.setState({ showModalPack: boolean });
    }

    setModalPackBundle = (boolean) => {
        this.setState({ showModalPackBundle: boolean });
    }

    salesModal = ({ isSalesActive, salesStopEpoch }) => {
        return <>
            <div className="buy-buttons background4 rounded-3 pb-2 pt-2 mb-3">
                {this.getSalesCountdown({ isSalesActive })}
                {this.getSalesButtons({ isSalesActive, salesStopEpoch })}
                <div className='mt-4'>
                    {this.getFreePackBanner({ isSalesActive, salesStopEpoch })}
                </div>
            </div>
        </>
    }

    getClaimButton = ({ isSalesActive, salesStopEpoch }) => {
        const BUTTON_CLASS = 'btn background2 font5 mt-2 mb-0 text-smaller rounded-pill';
        const BUTTON_TYPE = 'button';
        const BUTTON_STYLE = {};
        if (isSalesActive && (salesStopEpoch > Date.now()) && this.props.cards.length <= 0) {
            return <>
                <div>
                    <button style={BUTTON_STYLE} type={BUTTON_TYPE} className={BUTTON_CLASS} onClick={() => this.setModalFreePack(true)}>Claim pack</button>
                </div>
            </>
        }
        return <>
            <div>
                <button style={BUTTON_STYLE} type={BUTTON_TYPE} disabled className={BUTTON_CLASS}>Not eligible</button>
            </div>
        </>
    }

    getSalesButtons = ({ isSalesActive, salesStopEpoch }) => {
        const BUY_PACK_TEXT = 'Buy pack';
        const PACK_COST = '3.99$';
        const BUY_BUNDLE_TEXT = 'Buy bundle';
        const BUNDLE_COST = '34.99$';
        const BUTTON_CLASS = 'btn background2 font5 mt-4 px-3';
        const BUTTON_TYPE = 'button';
        if (isSalesActive && (salesStopEpoch > Date.now())) {
            return <>
                <div className='mb-1 d-flex justify-content-center'>
                    <div className='mx-2'>
                        <button type={BUTTON_TYPE} className={BUTTON_CLASS} onClick={() => this.setModalPack(true)}>
                            <p className='p-0 m-0'>
                                {BUY_PACK_TEXT}
                            </p>
                        </button>
                        <p className='p-0 m-0 mt-1 fs-6'>
                            <b>{PACK_COST}</b>
                        </p>
                    </div>
                    <div className='mx-2'>
                        <button type={BUTTON_TYPE} className={BUTTON_CLASS} onClick={() => this.setModalPackBundle(true)}>
                            <p className='p-0 m-0'>
                                {BUY_BUNDLE_TEXT}
                            </p>
                        </button>
                        <p className='p-0 m-0 mt-1 fs-6'>
                            <b>{BUNDLE_COST}</b>
                        </p>
                    </div>
                </div>
            </>
        }
        return <>
            <div>
                <button type={BUTTON_TYPE} disabled className={BUTTON_CLASS}>{BUY_PACK_TEXT}</button>
                <button type={BUTTON_TYPE} disabled className={BUTTON_CLASS}>{BUY_BUNDLE_TEXT}</button>
            </div>
        </>

    }

    getSalesCountdown = ({ isSalesActive }) => {
        const { currentEpoch } = this.state;
        if (!isSalesActive) {
            return <>
                <CountdownTimer currentEpoch={currentEpoch} text="Origins NFT set launch" showUntilText={false} endTimeEpoch={this.props.salesPeriod.originsSalesStart} subText="Available in:" />
            </>
        }
        const isFirstEditionPeriodActive = isSalesActive && (currentEpoch < (this.props.salesPeriod.originsSalesStart + this.props.salesPeriod.firstEditionPeriod));
        if (isFirstEditionPeriodActive) {
            return <>
                <CountdownTimer currentEpoch={currentEpoch} text="1st edition NFTs" showUntilText={true} endTimeEpoch={this.props.salesPeriod.originsSalesStart + this.props.salesPeriod.firstEditionPeriod} />
            </>
        }
        const isLimitedEditionPeriodActive = isSalesActive && (currentEpoch < (this.props.salesPeriod.originsSalesStart + this.props.salesPeriod.firstEditionPeriod + this.props.salesPeriod.limitedEditionPeriod));
        if (isLimitedEditionPeriodActive) {
            return <>
                <CountdownTimer currentEpoch={currentEpoch} text="Limited edition NFTs" showUntilText={true} endTimeEpoch={this.props.salesPeriod.originsSalesStart + this.props.salesPeriod.firstEditionPeriod + this.props.salesPeriod.limitedEditionPeriod} />
            </>
        }
        return <>
            <p className='countdown-title m-0'><b>NFT set expired</b></p>
            <p><b>Stay tuned for the next set!</b></p>
        </>
    }

    getFreePackBanner = ({ isSalesActive, salesStopEpoch }) => {
        return <div className="buy-buttons  pt-1">
            <p className='h6 color1 kaffeesatz m-0 p-0 px-1'><b>New collectors get 1<span style={{ fontSize: "13px" }}>x</span> pack for free!</b></p>
            {this.getClaimButton({ isSalesActive, salesStopEpoch })}
        </div>
    }


    getPackCard = ({ isSalesActive, salesStopEpoch }) => {
        return <>
            <h2>Collectible NFT trading cards</h2>
            <p><b>Authenticable, tradeable, storable &amp; decentralized<i>-ish</i></b></p>
            <div className="row">
                <div className="d-none d-sm-block col-12 col-lg-6 mb-2 buy-image">
                    <img alt="Three large Pawsters NFTCG card packs with different artwork" src="https://files.pawsters.io/packs/packs_3-lg.png" />
                </div>
                <div className="d-block d-sm-none col-12 mb-2 buy-image">
                    <img style={{ maxWidth: "200px" }} alt="Three small Pawsters NFTCG card packs with different artwork" src="https://files.pawsters.io/packs/packs_3-lg.png" />
                </div>
                <div className="col-12 col-lg-6 buy-info didact-gothic-regular">
                    <p>Pawsters NFTCG is a collectible card game utilizing blockchain technology, more specifically NFTs (non-fungible tokens). The usage of NFTs let our users <b>verify the authenticity</b> of cards, <b>store</b> cards in Ethereum wallets and <b>trade</b> cards as NFTs via secondary markets like <a href="https://opensea.io/">Opensea.io</a>.</p>
                    {this.salesModal({ isSalesActive, salesStopEpoch })}
                    <p>Pawsters NFTCG cards are bought in packs, with each pack containing 4 off-chain cards. Every card in every pack has the same chance of being rare, see the <a href="#probability">probability table</a> below. All Pawsters NFTCG cards can be <b>minted at any time</b>, if the user wants. You can pay for your cards with either a <b>Credit/Debit Card</b> or <b>Cryptocurrency</b>!</p>
                </div>
                <div className="col-12 offset-lg-6 col-lg-6 buy-info didact-gothic-regular">

                </div>
                <div className="d-none d-md-block col-12 buy-card-image">
                    <img alt="Three large Pawsters NFTCG card packs with different artwork" src="https://files.pawsters.io/info/more-cards-lg.png" />
                </div>
                <div className="d-block d-md-none d-flex justify-content-center col-12">
                    <img style={{ maxWidth: "250px", "margin": "-20px" }} alt="Three large Pawsters NFTCG card packs with different artwork" src="https://files.pawsters.io/info/more-cards-lg.png" />
                </div>
            </div>
        </>
    }

    getHowDoesItWork = () => {
        return <>
            <h2>How does it work?</h2>
            <p><b>Simply connect your wallet and start pulling cards from packs!</b></p>
            <div className="row">
                <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0">
                    <Card className='mb-2 info-card background4' style={{ alignItems: "center", display: "flex" }}>
                        <Card.Img className="info-card-image" variant="top" src="https://files.pawsters.io/info/acquire3.png" />
                        <h4><b>Acquire</b></h4>
                        <p className="didact-gothic-regular" style={{ maxWidth: "300px" }}>Connect your wallet and press the buy button! Proof of ownership is stored by us until you choose to mint a card.</p>
                    </Card>
                </div>
                <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0">
                    <Card className='mb-2 info-card background4' style={{ alignItems: "center", display: "flex" }}>
                        <Card.Img className="info-card-image" variant="top" src="https://files.pawsters.io/info/collect3.png" />
                        <h4><b>Collect</b></h4>
                        <p className="didact-gothic-regular" style={{ maxWidth: "300px" }}>Decide for yourself what tier you want to collect and start chasing cards!</p>
                    </Card>
                </div>
                <div className="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-0">
                    <Card className='mb-2 info-card background4' style={{ alignItems: "center", display: "flex" }}>
                        <Card.Img className="info-card-image" variant="top" src="https://files.pawsters.io/info/trade3.png" />
                        <h4><b>Trade</b></h4>
                        <p className="didact-gothic-regular" style={{ maxWidth: "300px" }}>Want to sell a rare card or prefer buying singles? Trade cards on secondary markets like Opensea.io!</p>
                    </Card>
                </div>
            </div>
        </>
    }

    getInDepthDescription = () => {
        return <>
            <h2>Details</h2>
            <div className="text-left">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <h5>Buying cards</h5>
                        <p className="didact-gothic-regular">You can buy cards with a credit/debit card through <a href="https://stripe.com" rel="noreferrer" target={"_blank"}>Stripe</a> or with cryptoassets via <a href="https://crypto.com/pay" rel="noreferrer" target={"_blank"}>Crypto.com Pay</a>. The proof of ownership of a card will be held by us until you decide to mint a card. As soon as you mint a card the proof of ownership will be transferred to the blockchain and the card will become tradeable. Transferring proof of ownership to the blockchain can only be done by the original owner the first time. Subsequent trades between wallets will only be available for the current owner at the time of the transaction.</p>
                        <p className="didact-gothic-regular">To keep costs down for our users we decided to let you determine which cards are minted and which are not. Using your connected wallet's address we can keep track of the cards you own, on-chain and off-chain. If you pull a card you like and want to store forever on a blockchain, you simply navigate to <a href="/collection">My Collection</a> and mint the card. When a card is minted it is forever stored on the Ethereum blockchain and is automatically tradeable on secondary markets like Opensea.io.</p>
                    </div>
                    <img style={{ height: '100%' }} className="d-none d-lg-block col-lg-4" alt="A Pawsters NFTCG card" src="https://files.pawsters.io/v2/sq/001_001_2_4_5.png" />
                </div>
                <div className="row">
                    <img style={{ height: '100%' }} className="d-none d-lg-block col-lg-4" alt="A Pawsters NFTCG card" src="https://files.pawsters.io/v2/sq/004_001_1_2_1.png" />
                    <div className="col-12 col-lg-8">
                        <h5>Rarity &#38; condition</h5>
                        <p className="didact-gothic-regular">Each card you discover in a card pack has the same chance of being rare. It is entirely possible to open a pack where all four cards are gem condition diamond rarity cards, but extremely rare.</p>
                        <p className="didact-gothic-regular">A card's details are determined the moment you buy it and can not be manipulated before or afterward. The species and color of a card are determined evenly between all species in a set, the same goes for a card's condition. However, when it comes to rarity it is a different matter. The probability table below shows how the card engine is tuned for different rarities.</p>
                        <div className="probability-table didact-gothic-regular" id="probability">
                            <table style={{ width: "100%", textAlign: "center" }}>
                                <tbody>
                                    <tr>
                                        <th className="kaffeesatz" style={{ fontSize: "22px" }}>Rarity</th>
                                        <th className="kaffeesatz" style={{ fontSize: "22px" }}>Probability</th>
                                    </tr>
                                    <tr>
                                        <td>Diamond</td>
                                        <td>0,5%</td>
                                    </tr>
                                    <tr>
                                        <td>Gold</td>
                                        <td>2%</td>
                                    </tr>
                                    <tr>
                                        <td>Silver</td>
                                        <td>8%</td>
                                    </tr>
                                    <tr>
                                        <td>Bronze</td>
                                        <td>20%</td>
                                    </tr>
                                    <tr>
                                        <td>Common</td>
                                        <td>69,5%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="didact-gothic-regular">Before buying a pack of cards you will notice a timer, this timer indicates how long a certain set of cards will be "printed". The First Edition cards of a certain set will only ever be available for 40 days starting from the date it is released. After the First Edition period is over, the Limited Edition period starts and lasts for another 180 days. In total, a set will be available for 220 days! If you manage to buy cards of any print run of any set, you can choose to mint them whenever you like regardless if the packs can be bought or not.</p>
                    </div>
                </div>
            </div>
        </>
    }

    render() {
        const { currentEpoch } = this.state;
        const isSalesActive = this.props.salesPeriod.originsSalesStart < currentEpoch;
        const salesStopEpoch = this.props.salesPeriod.originsSalesStart + this.props.salesPeriod.firstEditionPeriod + + this.props.salesPeriod.limitedEditionPeriod;

        return (
            <>
                <ClaimPackModal
                    className="buy-modal"
                    buyCardPackFunction={this.props.buyCardPackFunction}
                    show={this.state.showModalFreePack}
                    hide={() => this.setModalFreePack(false)}
                    cards={this.props.cards}
                    web3Options={this.props.web3Options}
                    connectFunction={this.props.connectFunction} />
                <BuyPackModal
                    className="buy-modal"
                    buyCardPackFunction={this.props.buyCardPackFunction}
                    show={this.state.showModalPack}
                    hide={() => this.setModalPack(false)}
                    web3Options={this.props.web3Options}
                    connectFunction={this.props.connectFunction} />
                <BuyPackBundleModal
                    className="buy-modal"
                    buyCardPackFunction={this.props.buyCardPackBundleFunction}
                    show={this.state.showModalPackBundle}
                    hide={() => this.setModalPackBundle(false)}
                    web3Options={this.props.web3Options}
                    connectFunction={this.props.connectFunction} />
                <div className="container-fluid buy-info-parent">
                    <div className="container p-0">
                        {this.getPackCard({ isSalesActive, salesStopEpoch })}
                    </div>
                </div>
                <div className="container-fluid background4 how-does-it-work-parent">
                    <div className="container p-0">
                        {this.getHowDoesItWork()}
                    </div>
                </div>
                <div className="container-fluid in-depth-parent">
                    <div className="container p-0">
                        {this.getInDepthDescription()}
                    </div>
                </div>
            </>
        );
    }
}

export default Body;

