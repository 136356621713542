import React from 'react';
import NavigationBar from './header/NavigationBar';

class Header extends React.Component {
    render() {
        return (
            <>
                <NavigationBar web3Options={this.props.web3Options} connectFunction={this.props.connectFunction} disconnectFunction={this.props.disconnectFunction} />
            </>
        );
    }
}

export default Header;
