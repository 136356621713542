import { getApiUrl } from '../config/ConfigService';

async function verifyPayment(pollingId) {
    try {
        const verifyPaymentUrl = `${getApiUrl()}/v1/payments/verify?pollingId=${pollingId}`;
        const paymentResponse = await fetch(verifyPaymentUrl);
        if (paymentResponse.status === 200) {
            const payment = await paymentResponse.json();
            return payment;
        }
    } catch (err) {
        return { status: "invalid" }
    }
}

export {
    verifyPayment
};
