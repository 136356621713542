import { getApiUrl } from '../config/ConfigService';
import { cards } from './mocks';

const MINIMUM_DELAY = 1000;

function getDnaFromTemplate(dnaTemplate, editionIndex, rarityIndex, conditionIndex) {
    return dnaTemplate.replace('e', editionIndex).replace('r', rarityIndex).replace('c', conditionIndex);
}

function getAllOriginsCards(editionIndex, rarityIndex, conditionIndex) {
    return {
        "cards": [
            {
                "dna": getDnaFromTemplate("001_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Drakoon 1/36",
                "cardNumber": "001",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("001_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("001_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("001_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("002_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Drakoon 2/36",
                "cardNumber": "002",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("002_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("002_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("002_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("003_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Drakoon 3/36",
                "cardNumber": "003",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("003_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("003_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("003_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("004_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Snox 4/36",
                "cardNumber": "004",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("004_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("004_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("004_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("005_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Snox 5/36",
                "cardNumber": "005",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("005_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("005_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("005_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("006_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Snox 6/36",
                "cardNumber": "006",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("006_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("006_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("006_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("007_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Peci 7/36",
                "cardNumber": "007",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("007_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("007_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("007_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("008_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Peci 8/36",
                "cardNumber": "008",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("008_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("008_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("008_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("009_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Peci 9/36",
                "cardNumber": "009",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("009_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("009_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("009_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("010_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Fenruff 10/36",
                "cardNumber": "010",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("010_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("010_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("010_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("011_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Fenruff 11/36",
                "cardNumber": "011",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("011_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("011_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("011_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("012_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Fenruff 12/36",
                "cardNumber": "012",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("012_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("012_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("012_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("013_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Caveer 13/36",
                "cardNumber": "013",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("013_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("013_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("013_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("014_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Caveer 14/36",
                "cardNumber": "014",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("014_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("014_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("014_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("015_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Caveer 15/36",
                "cardNumber": "015",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("015_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("015_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("015_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("016_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Pronkey 16/36",
                "cardNumber": "016",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("016_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("016_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("016_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("017_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Pronkey 17/36",
                "cardNumber": "017",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("017_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("017_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("017_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("018_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Pronkey 18/36",
                "cardNumber": "018",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("018_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("018_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("018_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("019_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Turkot 19/36",
                "cardNumber": "019",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("019_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("019_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("019_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("020_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Turkot 20/36",
                "cardNumber": "020",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("020_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("020_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("020_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("021_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Turkot 21/36",
                "cardNumber": "021",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("021_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("021_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("021_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("022_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Jirabi 22/36",
                "cardNumber": "022",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("022_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("022_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("022_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("023_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Jirabi 23/36",
                "cardNumber": "023",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("023_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("023_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("023_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("024_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Jirabi 24/36",
                "cardNumber": "024",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("024_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("024_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("024_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("025_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Relatyl 25/36",
                "cardNumber": "025",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("025_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("025_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("025_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("026_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Relatyl 26/36",
                "cardNumber": "026",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("026_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("026_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("026_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("027_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Relatyl 27/36",
                "cardNumber": "027",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("027_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("027_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("027_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("028_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Relatyl 28/36",
                "cardNumber": "028",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("028_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("028_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("028_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("029_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Relatyl 29/36",
                "cardNumber": "029",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("029_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("029_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("029_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("030_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Relatyl 30/36",
                "cardNumber": "030",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("030_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("030_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("030_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("031_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Avia 31/36",
                "cardNumber": "031",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("031_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("031_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("031_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("032_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Avia 32/36",
                "cardNumber": "032",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("032_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("032_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("032_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("033_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Avia 33/36",
                "cardNumber": "033",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("033_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("033_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("033_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("034_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Fossitar 34/36",
                "cardNumber": "034",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("034_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("034_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("034_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("035_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Fossitar 35/36",
                "cardNumber": "035",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("035_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("035_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("035_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            },
            {
                "dna": getDnaFromTemplate("036_001_e_c_r", editionIndex, rarityIndex, conditionIndex),
                "name": "Fossitar 36/36",
                "cardNumber": "036",
                "collectionSet": "Origins",
                "links": {
                    "imageLowQuality": `https://files.pawsters.io/v2/lq/${getDnaFromTemplate("036_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageStandardQuality": `https://files.pawsters.io/v2/sq/${getDnaFromTemplate("036_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`,
                    "imageHighQuality": `https://files.pawsters.io/v2/hq/${getDnaFromTemplate("036_001_e_c_r", editionIndex, rarityIndex, conditionIndex)}.png`
                }
            }

        ]
    }
}

async function getCardsWithMock(address, setMockedStateFunction, paginationIndex) {
    const cardDetailsUrl = (paginationIndex) ? `${getApiUrl()}/v1/cards/details?paginationIndex=${paginationIndex}` : `${getApiUrl()}/v1/cards/details`;
    const headers = { 'OwnerAddress': address }
    try {
        const response = await fetch(cardDetailsUrl, { headers });
        setMockedStateFunction(false);
        return response.json();
    } catch (err) {
        console.error('There was an error connecting to backend', err);
        setMockedStateFunction(true);
        await new Promise(r => setTimeout(r, MINIMUM_DELAY));
        return mockedGetCards();
    }
}

async function getCards(address, paginationIndex) {
    const cardDetailsUrl = (paginationIndex) ? `${getApiUrl()}/v1/cards/details?paginationIndex=${paginationIndex}` : `${getApiUrl()}/v1/cards/details`;
    const headers = { 'OwnerAddress': address }
    const response = await fetch(cardDetailsUrl, { headers });
    return response.json();
}

async function buyCardPackBundle(address, paymentId) {
    const start = Date.now();
    const cardsUrl = `${getApiUrl()}/v1/cards/bundle`;
    const response = await fetch(cardsUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'OwnerAddress': address
        },
        body: JSON.stringify({ paymentId })
    });
    const requestDurationMillis = Date.now() - start
    if (MINIMUM_DELAY - requestDurationMillis > 0) {
        await new Promise(r => setTimeout(r, MINIMUM_DELAY - requestDurationMillis));
    }
    return response.json();;
}

async function buyCardPack(address, paymentId) {
    const start = Date.now();
    const cardsUrl = `${getApiUrl()}/v1/cards`;
    const response = await fetch(cardsUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json',
            'OwnerAddress': address
        },
        body: JSON.stringify({ paymentId })
    });
    const requestDurationMillis = Date.now() - start
    if (MINIMUM_DELAY - requestDurationMillis > 0) {
        await new Promise(r => setTimeout(r, MINIMUM_DELAY - requestDurationMillis));
    }
    return response.json();
}


async function getCardMintPayload(address, cardIdentifier) {
    const mintPayloadUrl = `${getApiUrl()}/v1/mint/${cardIdentifier}`;
    const response = await fetch(mintPayloadUrl, {
        method: 'GET',
        headers: { 'OwnerAddress': address }
    });
    if (response.status === 200) {
        return response.json();
    }
}

async function getCardIdsByPollingId(pollingId, address) {
    const verifyPaymentUrl = `${getApiUrl()}/v1/payments/verify?pollingId=${pollingId}`;
    const paymentResponse = await fetch(verifyPaymentUrl);
    if (paymentResponse.status === 200) {
        const payment = await paymentResponse.json();
        if (payment.verified) {
            return payment.cardIdentifiers;
        }
        return [];
    }
    return [];
}

function mockedGetCards() {
    return cards();
}

export {
    getAllOriginsCards,
    getCards,
    getCardsWithMock,
    getCardMintPayload,
    mockedGetCards,
    buyCardPack,
    buyCardPackBundle,
    getCardIdsByPollingId
};
