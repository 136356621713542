import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CollectionCardModal from './CollectionCardModal';

function InfoCard(props) {
  const [showModal, setShowModal] = useState(false);

  const getStyle = () => {
    if (!props.preview) {
      return { cursor: "pointer" };
    }
    return {};
  }

  return (
    <>
      <CollectionCardModal
        className="card-modal"
        show={showModal}
        showButton={true}
        hide={() => setShowModal(false)}
        card={props.card}
        mintFunction={props.mintFunction} />
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 product-card p-2">
        <Card className='background5 shadow border-0' style={getStyle()} onClick={() => { if (!props.preview) { setShowModal(true) } }}>
          <Card.Title className="product-card-title kaffeesatz pt-1">{props.card.name}</Card.Title>
          <Card.Text className="product-card-id px-3">{props.card.identifier}</Card.Text>
          <Card.Img className="product-card-image" variant="top" src={props.card.links.imageLowQuality} />
          <Card.Body className="color1 product-card-body mt-2">
          </Card.Body>
          <div className="card-search" style={{ display: 'none' }}>
            <p className="card-search-dna">{props.card.dna}</p>
            <p className="card-search-minted">{(props.card.minted) ? "Minted" : "Not minted"}</p>
            <p className="card-search-name">{props.card.name}</p>
            <p className="card-search-identifier">{props.card.identifier}</p>
            <p className="card-search-species">{props.card.species}</p>
            <p className="card-search-color">{props.card.color}</p>
            <p className="card-search-set">{props.card.collectionSet}</p>
            <p className="card-search-condition">{props.card.condition}</p>
            <p className="card-search-rarity">{props.card.rarity}</p>
          </div>
        </Card >
      </div>
    </>
  );
}

export default InfoCard;