import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/HomeRounded';
import AccountIcon from '@mui/icons-material/AccountCircle';
import ConnectIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import BuyIcon from '@mui/icons-material/PaymentsRounded';
import CollectionIcon from '@mui/icons-material/StyleRounded';
import LibraryIcon from '@mui/icons-material/MenuBookRounded';
import FaqIcon from '@mui/icons-material/QuizRounded';

import ProfileModal from './ProfileModal';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routeKey: this.getRouteKey(),
      showModal: false
    };
  }

  setModal = (boolean) => {
    this.setState({ showModal: boolean });
  }

  getSmallHomeButton = () => {
    return <Nav activeKey={this.state.routeKey} className="d-flex flex-row">
      <Nav.Link className='mx-2 p-1 text-small' eventKey="0" onClick={() => this.setRouteKey("0")} as={Link} to="/">
        <HomeIcon sx={{ fontSize: "20px" }} />
        <p className='m-0'>Home</p>
      </Nav.Link>
    </Nav>
  }

  getSmallConnectButton = () => {
    if (this.props.web3Options && this.props.web3Options.account) {
      return <button onClick={() => this.setModal(true)} className="btn btn-transparent border-0 text-small navbar-dark">
        <AccountIcon sx={{ fontSize: "20px" }} />
        <p className='m-0'>Session</p>
      </button>
    }
    return <button onClick={this.props.connectFunction} className="btn btn-transparent border-0 text-small navbar-dark">
      <ConnectIcon sx={{ fontSize: "20px" }} />
      <p className='m-0'>Connect</p>
    </button>

  }

  getConnectButton = () => {
    if (this.props.web3Options && this.props.web3Options.account) {
      return <>
        <button id="navProfileButton" onClick={() => this.setModal(true)} type="button" className="btn btn-light profile-button navbar-dark">
          <AccountIcon />
          <p className='m-0 p-0 fs-7'>Session</p>
          </button>
        
      </>

    } else {
      return <>
        <button id="navDisconnectButton" onClick={this.props.connectFunction} type="button" className="btn btn-light align-middle navbar-dark"><ConnectIcon className='align-top' sx={{ fontSize: "24px" }} /><span className='align-top fs-7 ps-1'> Connect wallet</span></button>
      </>
    }
  }

  getRouteKey = () => {
    const routeKeys = new Map([["/", "0"], ["/collection", "1"], ["/cards", "2"], ["/faq", "3"]]);
    const theKey = routeKeys.get(window.location.pathname);
    return theKey;
  }

  setRouteKey = (newKey) => {
    this.setState({ routeKey: newKey })
  }

  render() {
    return (
      <>
        <ProfileModal
          className="profile-modal"
          show={this.state.showModal}
          hide={() => this.setModal(false)}
          web3Options={this.props.web3Options}
          disconnectFunction={this.props.disconnectFunction}
        />
        { /* Desktop view navbar */}
        <Navbar collapseOnSelect={true} fixed="top" className="background5 shadow rounded nav d-none d-sm-block p-0">
          <Container className='p-0'>
            <Navbar.Brand className="navbar-brand-regular d-none d-md-block">
              <Link onClick={() => this.setRouteKey("0")} to="/">
                <img
                  alt=""
                  src="/pawsters-nftcg-logo.svg"
                  height="75"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Brand className="navbar-brand-small d-block d-md-none">
              <Link onClick={() => this.setRouteKey("0")} to="/">
                <img
                  alt=""
                  src="/pawsters-nftcg-logo.svg"
                  height="50"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav activeKey={this.state.routeKey} className="me-auto">
                {/*<Nav.Link eventKey="0" onClick={() => this.setRouteKey("0")} as={Link} to="/">
                  <HomeIcon sx={{ fontSize: "20px" }} />
                  <p className='m-0 fs-6'>Home</p>
                </Nav.Link>*/}
                <Nav.Link eventKey="0" className="pb-1 pt-0" onClick={() => this.setRouteKey("0")} as={Link} to="/">
                  <BuyIcon sx={{ fontSize: "20px" }} />
                  <p className='m-0 fs-7'>Buy</p>
                </Nav.Link>
                <Nav.Link eventKey="1" className="pb-1 pt-0" onClick={() => this.setRouteKey("1")} as={Link} to="/collection">
                  <CollectionIcon sx={{ fontSize: "20px" }} />
                  <p className='m-0 fs-7'>Collection</p>
                </Nav.Link>
                <Nav.Link eventKey="2" className="pb-1 pt-0" onClick={() => this.setRouteKey("2")} as={Link} to="/library">
                  <LibraryIcon sx={{ fontSize: "20px" }} />
                  <p className='m-0 fs-7'>Library</p>
                </Nav.Link>
                <Nav.Link eventKey="3" className="pb-1 pt-0" onClick={() => this.setRouteKey("3")} as={Link} to="/faq">
                  <FaqIcon sx={{ fontSize: "20px" }} />
                  <p className='m-0 fs-7'>FAQ</p>
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto">
                <Nav.Item >
                  {this.getConnectButton()}
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar >

        { /* Mobile view navbar */}
          <Navbar collapseOnSelect={true} fixed="top" className="background5 shadow rounded nav d-block d-sm-none p-0">
          <Container className='pe-2 ps-2'>
            <Navbar.Brand className="navbar-brand-small">
              <Link onClick={() => this.setRouteKey("0")} to="/">
                <img
                  alt=""
                  src="/pawsters-nftcg-logo.svg"
                  height="50"
                />
              </Link>
            </Navbar.Brand>
            <Nav className="">
              <Nav.Item >
                {/* 
                {this.getSmallHomeButton()}
                */}
              </Nav.Item>
            </Nav>
            <Nav className="">
              <Nav.Item >
                <div className='d-block d-md-none'>
                  {this.getSmallConnectButton()}
                </div>
                <div className='d-none d-md-block p-2'>
                  {this.getConnectButton()}
                </div>
              </Nav.Item>
            </Nav>
          </Container>
        </Navbar >
        <Navbar collapseOnSelect={true} fixed="bottom" className="background5 p-0 pb-3 shadow-lg rounded nav d-block d-sm-none">
          <Container className='d-flex justify-content-center'>
            <Nav activeKey={this.state.routeKey} className="d-flex flex-row">
              <Nav.Link className='mx-2 p-1 text-small' eventKey="0" onClick={() => this.setRouteKey("0")} as={Link} to="/">
                <BuyIcon sx={{ fontSize: "20px" }} />
                <p className='m-0'>Buy</p>
              </Nav.Link>
              <Nav.Link className='mx-2 p-1 text-small' eventKey="1" onClick={() => this.setRouteKey("1")} as={Link} to="/collection">
                <CollectionIcon sx={{ fontSize: "20px" }} />
                <p className='m-0'>Collection</p>
              </Nav.Link>
              <Nav.Link className='mx-2 p-1 text-small' eventKey="2" onClick={() => this.setRouteKey("2")} as={Link} to="/library">
                <LibraryIcon className='m-0 p-0' sx={{ fontSize: "20px" }} />
                <p className='m-0'>Library</p>
              </Nav.Link>
              <Nav.Link className='mx-2 p-1 text-small' eventKey="3" onClick={() => this.setRouteKey("3")} as={Link} to="/faq">
                <FaqIcon sx={{ fontSize: "20px" }} />
                <p className='m-0'>FAQ</p>
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar >
      </>
    );
  }
}

export default NavigationBar;