function getApiUrl() {
    return process.env.REACT_APP_PAWSTERS_CLOUD_API_URL // !NOTE this field is network dependant
}

function getSpaUrl() {
    return process.env.REACT_APP_SPA_URL// !NOTE this field is network dependant
}

function getImageUrl() {
    return process.env.REACT_APP_PAWSTERS_FILES_URL // production
}

export {
    getApiUrl,
    getSpaUrl,
    getImageUrl
}