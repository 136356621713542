import React from 'react';
import Accordion from 'react-bootstrap/Accordion'

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    /**
    * React hook
    */
    componentDidMount() {
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG - FAQ"
        } else {
            document.title = "Pawsters NFTCG - FAQ (dev)"
        }
    }

    render() {
        return (
            <>
                <div style={{ marginTop: '50px', marginBottom: '30px' }}>
                    <div className="jumbotron jumbotron-fluid background5 color1">
                        <div className="container p-1 faq-body didact-gothic-regular">
                            <h1 className="faq-title kaffeesatz">Frequently Asked Questions</h1>
                            <Accordion defaultActiveKey="0" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><strong>What does World First Edition mean?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        All cards come in different editions, "World First" edition means that it is the first card
                                        in the world to be generated with that specific combination of attributes.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><strong>What does First Edition mean?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        All cards come in different editions, "First" edition means that it is generated within 40
                                        days of the current cards release date.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><strong>What does Limited Edition mean?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        All cards come in different editions, "Limited" edition means that it is generated after the
                                        first 40 days of the current cards release date but before the card set is retired. A card set 
                                        is retired after the Limited Edition cards have been available for 180 days.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><strong>Are all cards minted when bought?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, we do not mint the cards when they are bought. Instead, all cards are mintable at your
                                        convenience. This is an active decision to keep costs down for both you and us.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><strong>Why are cards not minted directly?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        We made an active decision to not mint cards directly because that would increase the cost of
                                        our cards tremendously. Like grading cards in real life, we believe that minting will
                                        be reserved for our users more precious cards.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><strong>Can I trade my cards?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        Yes! All minted cards are tradeable at any Ethereum ERC-721 compliant marketplace, like
                                        <a href="https://opensea.io">opensea.io</a> or <a href="https://rarible.com">rarible.com</a>.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><strong>Can someone counterfeit Pawsters NFTCG cards?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, even if anyone can copy or art (since it is publicly available), you can easily verify if a card
                                        is real or not by looking at the contract. ALL authentic Pawster NFTCG cards are visible on
                                        our site and any minted cards will be minted via our smart contract.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header><strong>Someone is selling a card on Opensea, how can I verify that it is real?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        Make sure that the contract is our contract, it is verified and available for anyone to look at, at
                                        <a href="https://etherscan.com">etherscan.com</a>.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header><strong>Is minting free?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, If we could decide it would at least be very cheap. But, the cost of minting is decided by the Ethereum networks current
                                        gas cost. Therefore we decided to let our users mint anytime at their convenience.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header><strong>What are the odds of getting a specific card?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        The probability of getting a certain attribute is described near the bottom of the startpage, in the
                                        "Details" section.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header><strong>How long are world first edition cards available?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        World first edition cards are available until a set is retired.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header><strong>How long are first edition cards available?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        First edition cards are available during the first 40 days of a card set's release.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header><strong>How long are limited edition cards available?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        Limited edition cards are availble 40 days after a card set's release and until it is retired. A card set 
                                        is retired after the Limited Edition cards have been available for 180 days
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header><strong>Are the cards usable in a card game?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, but we would like to evolve Pawsters NFTCG in to a full card game in the future.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header><strong>Are the cards available physically as well?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, Pawsters NFTCG are limited to a digital format. Seeing that the NFT aspect is a core feature
                                        of Pawsters NFTCG, physical cards would not make sense. Howerver, we would like to do some
                                        promotional physical cards in the future.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header><strong>Is there a roadmap available?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, nothing that we have offically commited to. Please send any feature requests to support@pawsters.io,
                                        we would love to hear your feedback and feature requests!
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header><strong>Is there a Pawsters NFTCG token?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, that is not planned at the moment.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                    <Accordion.Header><strong>How do I pay?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        All Credit/Debit Card payments are handled and processed by <a href="https://stripe.com/" rel="noreferrer" target={"_blank"}>Stripe</a>. All Cryptocurrency payments are handled and processed by <a href="https://crypto.com/pay" rel="noreferrer" target={"_blank"}>Crypto.com Pay</a>.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="18">
                                    <Accordion.Header><strong>Do you save any customer data?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        We save wallet addresses to track what cards belong to whom and we also keep a history of all payments.
                                        We believe that it is our responsibility to only save data that is essential for our product.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="19">
                                    <Accordion.Header><strong>Can I get a refund?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, since cards are generated randomly when bought, refunds are not offered.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="20">
                                    <Accordion.Header><strong>I used the wrong address, can you transfer my cards?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, we can't interfere with who owns what card due to the decentralized nature of NFTs.
                                        Your keys, your cards.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="21">
                                    <Accordion.Header><strong>I minted the wrong card, can I get a refund?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        No, the minting process is completely decentralized and the only fee is the gas fee.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="22">
                                    <Accordion.Header><strong>I was hacked, are my cards lost?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        The only way for you to be hacked is if you lose your crypto wallet keys, a hacker could then transfer
                                        your minted cards to another address. Minting and transferring cards incur gas fees, all your cards
                                        are not necessarily lost but it will of course depend on the hacker. As always keep your keys safe
                                        and do not share them!
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="23">
                                    <Accordion.Header><strong>Can I review the smart contract?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        Yes! Our contract if verified and available on <a target={"_blank"} rel="noreferrer" href="https://etherscan.com">etherscan.com</a>.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="24">
                                    <Accordion.Header><strong>Can I read the Terms & Conditions?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        Of course, it is available <a href="/terms">here</a>.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="25">
                                    <Accordion.Header><strong>Can I read the privacy policy?</strong></Accordion.Header>
                                    <Accordion.Body>
                                        Absolutely, and you should! It is available <a href="/privacy">here</a>.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Body;

