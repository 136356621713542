import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import RefreshIcon from '@mui/icons-material/Refresh';
import LargeViewIcon from '@mui/icons-material/GridViewOutlined'
import SmallViewIcon from '@mui/icons-material/ViewCompactOutlined'
import MediumViewIcon from '@mui/icons-material/ViewModuleOutlined'
import ListViewIcon from '@mui/icons-material/ViewHeadlineOutlined'
import LinkIcon from '@mui/icons-material/Link';

import ShareModal from './ShareModal';
import InfoCard from './InfoCard';
import Pagination from './Pagination';
import { matchesSearch } from '../../../functions/matchesSearch';

class CardBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      cards: [],
      cardsLoading: false,
      showShareModal: false,
      viewType: "LARGE"
    };
  }

  paginatedCardBox = (cards, mintFunction, preview, currentPage, onPageChangedFunction) => {
    const CARDS_PER_PAGE = 84;
    const pages = Math.floor(cards.length / CARDS_PER_PAGE);
    if (pages > 1) {
      const startIndex = (currentPage - 1) * CARDS_PER_PAGE;
      const showingStart = startIndex + 1
      const showingEnd = ((startIndex + CARDS_PER_PAGE) > cards.length) ? cards.length : startIndex + CARDS_PER_PAGE;
      const cardsSubset = cards.slice(startIndex, startIndex + CARDS_PER_PAGE);
      return <>
        <div className="d-flex justify-content-center didact-gothic-regular">
          <Pagination totalPages={Math.ceil(cards.length / CARDS_PER_PAGE)} totalRecords={cards.length} pageLimit={CARDS_PER_PAGE} pageNeighbours={0} onPageChanged={onPageChangedFunction} />
        </div>
        <p className="didact-gothic-regular">Showing {showingStart}-{showingEnd} of {cards.length}</p>
        {cardsSubset.map(card =>
          <InfoCard viewType={this.state.viewType} key={card.identifier} mintFunction={mintFunction} card={card} preview={preview} />)}
      </>
    }
    return cards.map(card =>
      <InfoCard viewType={this.state.viewType} key={card.identifier} mintFunction={mintFunction} card={card} preview={preview} />);
  }

  setViewType = ({ newViewType }) => {
    this.setState({ viewType: newViewType })
  }

  setShowModal(newValue) {
    this.setState({ showShareModal: newValue });
  }

  getCardContainerContents(props, cards, currentPage, onPageChangedFunction) {
    if (!(props.cardsLoading) && props.cards.length <= 0) {
      return <>
        <p>You don't have any cards, buy some card packs first!</p>
      </>
    }
    if (props.cardsLoading || this.state.cardsLoading) {
      return <>
        <div className='containr d-flex justify-content-center'>
          <div className="row">
            <div className="col-12">
              <p>Loading...</p>
            </div>
            <div className="col-12">
              <Spinner animation="border" variant="secondary" />
            </div>
          </div>
        </div>
      </>
    }
    return this.paginatedCardBox(cards, props.mintFunction, props.preview, currentPage, onPageChangedFunction);
  }

  getRefreshButton(props) {
    if (props.cards.length > 0 && !props.preview) {
      return <>
        <button className="btn p-0" onClick={async () => {
          this.setState({ cardsLoading: true });
          await props.getCardsFunction(false);
          this.setCards({ matchesSearchFunction: matchesSearch })
          this.setState({ cardsLoading: false });
        }}>
          <RefreshIcon fontSize="medium" />
        </button>
      </>
    }
  }

  onPageChanged = (data) => {
    const { currentPage } = data;
    this.setState({ currentPage });
  }

  setCards({ newFilters, matchesSearchFunction }) {
    this.setState({ cards: this.props.cards.filter(card => matchesSearchFunction({ newFilters, card })) });
    this.setState({ currentPage: 1 });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cards !== this.props.cards) {
      this.setState({ cards: this.props.cards });
    }
  }

  searchBox() {
    return (
      <>
        <div className="container card-container" style={{ marginTop: "50px" }}>
          <div className="row">
            <div className="col-sm-7 col-md-7 offset-md-1 search-group">
              <div className='container' style={{ width: '100%', marginTop: '5px' }}>
                <div className="input-group rounded">
                  <input id="searchBoxInput" type="search" className="form-control rounded" placeholder="Search..." aria-label="Search"
                    aria-describedby="search-addon" onInput={() => this.setCards({ matchesSearchFunction: matchesSearch })} />
                </div>
              </div>
            </div>
            <div className='col-sm-5 col-md-3 search-group'>
              <div className='container' style={{ width: '100%', marginTop: '5px' }}>
                {this.filterDropdown()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  filterDropdown() {
    const options = [
      {
        "labelKey": "HeaderRarity",
        "value": "Rarity",
        "isSelected": false,
        "isDisabled": true
      },
      {
        "labelKey": "ItemRarity-Common",
        "value": "Common",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemRarity-Bronze",
        "value": "Bronze",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemRarity-Silver",
        "value": "Silver",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemRarity-Gold",
        "value": "Gold",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemRarity-Diamond",
        "value": "Diamond",
        "isSelected": false,
        "isDisabled": false
      }, {
        "labelKey": "HeaderCondition",
        "value": "Condition",
        "isSelected": false,
        "isDisabled": true
      },
      {
        "labelKey": "ItemCondition-Damaged",
        "value": "Damaged",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemCondition-Good",
        "value": "Good",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemCondition-Excellent",
        "value": "Excellent",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemCondition-Gem",
        "value": "Gem",
        "isSelected": false,
        "isDisabled": false
      }
      ,
      {
        "labelKey": "HeaderSet",
        "value": "Set",
        "isSelected": false,
        "isDisabled": true
      },
      {
        "labelKey": "ItemSet-Origins",
        "value": "Origins",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "HeaderMinted",
        "value": "NFT",
        "isSelected": false,
        "isDisabled": true
      },
      {
        "labelKey": "ItemMinted-True",
        "value": "Minted",
        "isSelected": false,
        "isDisabled": false
      },
      {
        "labelKey": "ItemMinted-False",
        "value": "Not minted",
        "isSelected": false,
        "isDisabled": false
      },
    ]
    return (
      <>
        {
          <BootstrapSelect options={options} isMultiSelect={true} showTick={true} onChange={(selectedOptions) => this.dropdownSearch(selectedOptions)} placeholder="No filter" className="filter-dropdown" />
        }
      </>
    );
  }

  dropdownSearch(selectedOptions) {
    const createFilters = () => {
      const filters = {
        "ItemRarity": [],
        "ItemCondition": [],
        "ItemSet": [],
        "ItemMinted": []
      };
      selectedOptions.selectedKey.forEach((currentValue, index) => filters[currentValue.split("-")[0]].push(selectedOptions.selectedValue[index]))
      return filters;
    }
    const filters = createFilters(selectedOptions);
    this.setCards({ newFilters: filters, matchesSearchFunction: matchesSearch })
  }

  getShareButton = () => {
    return <>
      <div style={{ marginTop: '10px' }}>
        <button className="btn btn-outline-dark" onClick={() => this.setShowModal(true)}>Share &nbsp;&nbsp;<LinkIcon /></button>
      </div>
    </>
  }

  getButtonColor = ({ viewType }) => {
    return (this.state.viewType === viewType) ? "#212529" : "#a4bac8"
  }

  render() {
    return (
      <>
        <ShareModal
          className="profile-modal"
          show={this.state.showShareModal}
          hide={() => this.setShowModal(false)}
          address={this.props.address}
        />
        {this.searchBox()}
        {this.getShareButton()}
        <div className="container">
          <div className='m-2 mt-5'>
            <div className="row justify-content-center">
              <h4>{this.props.title}</h4>
              <hr className="background2 col-6" />
            </div>
            <div className="row m-0">
              <div className="card-box-actions mb-4 p-0">
                <div>
                  {this.getRefreshButton(this.props)}
                </div>
                <div className='mx-1'>
                  <button className="btn p-0" onClick={() => this.setViewType({ newViewType: "LARGE" })}>
                    <LargeViewIcon fontSize="medium" htmlColor={this.getButtonColor({ viewType: "LARGE" })} />
                  </button>
                  <button className="btn p-0" onClick={() => this.setViewType({ newViewType: "MEDIUM" })}>
                    <MediumViewIcon fontSize="medium" htmlColor={this.getButtonColor({ viewType: "MEDIUM" })} />
                  </button>
                  <button className="btn p-0" onClick={() => this.setViewType({ newViewType: "SMALL" })}>
                    <SmallViewIcon fontSize="medium"  htmlColor={this.getButtonColor({ viewType: "SMALL" })} />
                  </button>
                  <button className="btn p-0" onClick={() => this.setViewType({ newViewType: "LIST" })}>
                    <ListViewIcon fontSize="medium" htmlColor={this.getButtonColor({ viewType: "LIST" })} />
                  </button>
                </div>
              </div>
            </div>
            <div className='row' style={{ padding: "2px" }} id='cardContainer'>
              {this.getCardContainerContents(this.props, this.state.cards, this.state.currentPage, this.onPageChanged)}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CardBox;