import React from 'react';
import CardBox from './CardBox';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import ConnectIcon from '@mui/icons-material/AccountBalanceWalletRounded';

import BlurrableCardBox from './BlurrableCardBox';
import { mockedGetCards } from '../../../functions/services/cards/CardService';

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storageValue: 0,
            currentRadioValue: this.radios.values().next().value.value,
            currentCollectionSet: "001",
            currentCollectionRarity: "1",
            connected: false
        };
    }

    /**
    * React hook
    */
    componentDidMount() {
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG - Collection"
        } else {
            document.title = "Pawsters NFTCG - Collection (dev)"
        }
    }

    radios = [
        { name: 'All cards', value: '1' },
        { name: 'Collections', value: '2' }
    ];

    SETS = [
        {
            "labelKey": "001",
            "value": "Origins",
            "isSelected": true
        }
    ];

    RARITIES = [
        {
            "labelKey": "1",
            "value": "Common",
            "isSelected": true,
            "isDisabled": false
        },
        {
            "labelKey": "2",
            "value": "Bronze",
            "isSelected": false,
            "isDisabled": false
        },
        {
            "labelKey": "3",
            "value": "Silver",
            "isSelected": false,
            "isDisabled": false
        },
        {
            "labelKey": "4",
            "value": "Gold",
            "isSelected": false,
            "isDisabled": false
        },
        {
            "labelKey": "5",
            "value": "Diamond",
            "isSelected": false,
            "isDisabled": false
        }
    ];

    setRadioValue = (newValue) => {
        this.setState({ currentRadioValue: newValue });
    }

    changeCollectionRarity = (selectedOptions) => {
        const newRarityIndex = selectedOptions.selectedKey[0];
        this.setState({ currentCollectionRarity: newRarityIndex });
    }

    changeCollectionSet = (selectedOptions) => {
        const newSetIndex = selectedOptions.selectedKey[0];
        this.setState({ currentCollectionSet: newSetIndex });
    }

    getCollectionDropDowns = () => {
        return (
            <>
                <div className="container">
                    <div className="m-2">
                        <div className="row justify-content-center">
                            <div className="col-6 col-sm-4 col-lg-3">
                                <p className="library-dropdown-text">Set</p>
                                <BootstrapSelect options={this.SETS} isMultiSelect={false} showTick={false} onChange={this.changeCollectionSet} placeholder="Set..." className="filter-dropdown" />
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3">
                                <p className="library-dropdown-text">Rarity</p>
                                <BootstrapSelect options={this.RARITIES} isMultiSelect={false} showTick={false} onChange={this.changeCollectionRarity} placeholder="Rarity..." className="filter-dropdown" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    getConnectWalletBanner = () => {
        return <>
            <div className="jumbotron jumbotron-fluid background2 color4 connect-banner p-5 mt-5">
                <h1 className="banner-header">Connect wallet</h1>
                <p className="didact-gothic-regular">Connect your wallet to view and manage your collection of Pawsters NFTCG cards</p>
                <hr className="line-quarter background4 mb-4" />
                <button id="bannerConnectButton" onClick={this.props.connectFunction} type="button" className="btn btn-light kaffeesatz"><ConnectIcon className='align-top' sx={{ fontSize: "24px" }} /><span className='align-top fs-7 ps-1 kaffeesatz'> Connect wallet</span></button>
            </div>
        </>
    }
    getBlurredAllCards = () => {
        return <>
            {this.getConnectWalletBanner()}
            <div style={{ filter: "blur(8px)", WebkitFilter: "blur(8px)" }}>
                <CardBox getCardsFunction={this.props.getCardsFunction} title="All Cards" cards={mockedGetCards().cards} preview={true} />
            </div>
        </>;
    }

    getCollectionTitle = () => {
        const selectedSet = this.SETS.find(set => set.labelKey === this.state.currentCollectionSet)
        return `My ${selectedSet.value} Collection`;
    }

    getCardCollection = () => {
        const selectedRarity = this.RARITIES.find(rarity => rarity.labelKey === this.state.currentCollectionRarity)
        return <>
            {this.getRadioButtons()}
            {this.getCollectionDropDowns()}
            <BlurrableCardBox getCardsFunction={this.props.getCardsFunction} title={this.getCollectionTitle()} ownedCards={this.props.cards} set={this.state.currentCollectionSet} rarity={this.state.currentCollectionRarity} rarityDisplayName={selectedRarity} />
        </>;
    }

    getAllCards = () => {
        return <>
            {this.getRadioButtons()}
            <div className="card-container">
                <CardBox address={this.props.web3Options.account} getCardsFunction={this.props.getCardsFunction} mintFunction={this.props.mintFunction} title="All Cards" cards={this.props.cards} cardsLoading={this.props.cardsLoading} />
            </div>
        </>;
    }

    getCollectionBody = () => {
        if (this.props.web3Options?.connected) {
            if (this.state.currentRadioValue === '2') {
                return this.getCardCollection();
            } else {
                return this.getAllCards();
            }
        }
        return this.getBlurredAllCards();
    }

    getRadioButtons = () => {
        return <>
            <ButtonGroup>
                {this.radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        size='md'
                        variant='outline-dark'
                        name="collectionRadioButtons"
                        value={radio.value}
                        checked={this.state.currentRadioValue === radio.value}
                        onChange={(e) => this.setRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    }

    getMockedBanner = (isMocked) => {
        if (isMocked) {
            return <>
                <div className="jumbotron jumbotron-fluid background1 font5" style={{ padding: '10px', paddingTop: '30px' }}>
                    <h3>Failed to load cards, showing demo</h3>
                </div>
            </>
        }
        return <></>
    }

    render() {
        return (
            <>
                {this.getMockedBanner(this.props.isMocked)}
                {((this.props.isMocked)) ? <>
                    <div style={{ marginTop: '50px' }}>
                        {this.getCollectionBody()}
                    </div>
                </> :
                    <>
                        <div style={{ marginTop: '50px' }}>
                            {this.getCollectionBody()}
                        </div>
                    </>
                }
            </>
        );
    }
}

export default Body;

