import { Link } from "react-router-dom";

function HomeButton(props) {
  return (
    <Link to="/">
      <button style={{ margin: "10px" }} type="button" className="btn background2 font5 didact-gothic-regular">{props.title}</button>
    </Link>
  );
}

export default HomeButton;
