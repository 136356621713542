import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import HomeButton from '../../../shared/navigation/HomeButton';
import CardBox from './CardBox';
import { getCardIdsByPollingId, getCards } from '../../../../functions/services/cards/CardService';

const POLL_DELAY = 1_000;

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCardsReady: false,
            cards: []
        };
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const source = params.get('source');
        if (source === "backend") {
            window.close();
        } else {
            if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
                document.title = "Pawsters NFTCG - Payment sucessful"
            } else {
                document.title = "Pawsters NFTCG - Payment successful (dev)"
            }
        }
        const pollingId = params.get('pollingId');
        const address = params.get('address');
        this.loadInterval = setInterval(() => this.loadCards(pollingId, address), POLL_DELAY);
    }

    componentWillUnmount() {
        this.loadInterval && clearInterval(this.loadInterval);
        this.loadInterval = false;
    }

    loadCards = async (pollingId, address) => {
        const purchasedCardIdentifiers = await getCardIdsByPollingId(pollingId, address)
        if (purchasedCardIdentifiers !== []) {
            // Stop verifying
            clearInterval(this.loadInterval);
            const cardList = [];
            let cardsResponse = await getCards(address);
            cardList.push(...cardsResponse.cards.filter(card => purchasedCardIdentifiers.includes(card.identifier)));
            this.setState({ cards: cardList, isCardsReady: true });
            while (cardsResponse.hasMoreCards) {
                const newPaginationIndex = cardsResponse.currentPaginationIndex + 1;
                cardsResponse = await getCards(address, newPaginationIndex);
                cardList.push(...cardsResponse.cards.filter(card => purchasedCardIdentifiers.includes(card.identifier)));
                this.setState({ cards: cardList });
            }
            // Refresh card page
            this.props.getCardsFunction()
        }
    }

    showNewCards = () => {
        return <>
            <CardBox cards={this.state.cards} />
        </>
    }

    waitForCards = () => {
        return <>
            <div className='container d-flex justify-content-center'>
                <div className="row">
                    <div className="col-12">
                        <p>Loading...</p>
                    </div>
                    <div className="col-12">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                </div>
            </div>
        </>
    }

    render() {

        window.gtag('event', 'conversion', {
            'send_to': 'AW-11053889888/PW6TCKfGt4YYEODy85Yp',
            'transaction_id': ''
        });

        return (
            <>
                <div style={{ marginTop: '100px' }}>
                    <div className="container">
                        <h1>Success!</h1>
                        <p>The cards below have been added to your collection</p>
                        <HomeButton title="Go back" />
                        {(this.state.isCardsReady) ? this.showNewCards() : this.waitForCards()}
                    </div>
                </div>
            </>
        );
    }
}

export default Body;

