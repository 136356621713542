import React from 'react';
import LibraryCardBox from './LibraryCardBox';
import LibraryDropdowns from './LibraryDropdowns';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { getAllOriginsCards } from '../../../functions/services/cards/CardService';

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editionIndex: "1",
            rarityIndex: "1",
            conditionIndex: "1",
            currentRadioValue: this.radios.values().next().value.value
        };
    }

    /**
    * React hook
    */
    componentDidMount() {
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG - Library"
        } else {
            document.title = "Pawsters NFTCG - Library (dev)"
        }
    }

    radios = [
        { name: 'Origins', value: '1' },
        { name: 'Origins Promos', value: '2' }
    ];

    changeRadioValue = (newValue) => {
        this.setState({ currentRadioValue: newValue });
    }

    changeRarity = (selectedOptions) => {
        const newRarityIndex = selectedOptions.selectedKey[0];
        this.setState({ rarityIndex: newRarityIndex });
    }

    changeCondition = (selectedOptions) => {
        const newConditionIndex = selectedOptions.selectedKey[0];
        this.setState({ conditionIndex: newConditionIndex });
    }

    changeEdition = (selectedOptions) => {
        const newEditionIndex = selectedOptions.selectedKey[0];
        this.setState({ editionIndex: newEditionIndex });
    }

    getRadioButtons = () => {
        return <>
            <ButtonGroup>
                {this.radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        size='md'
                        variant='outline-dark'
                        name="cardsRadioButtons"
                        value={radio.value}
                        checked={this.state.currentRadioValue === radio.value}
                        onChange={(e) => this.changeRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    }

    getSelectedSet = () => {
        switch (this.state.currentRadioValue) {
            case "1":
                return <LibraryCardBox cards={getAllOriginsCards(this.state.editionIndex, this.state.rarityIndex, this.state.conditionIndex).cards} showButton={false} />
            case "2":
                return <><p className="fs-3 mt-5">Not available, yet!</p></>
            default:
                return <LibraryCardBox cards={getAllOriginsCards(this.state.editionIndex, this.state.rarityIndex, this.state.conditionIndex).cards} showButton={false} />
        }
    }

    render() {
        return (
            <>
                <div style={{ marginTop: '50px', }}>
                    <div className="container p-0">
                        {this.getRadioButtons()}
                        <LibraryDropdowns changeEditionFunction={this.changeEdition} changeRarityFunction={this.changeRarity} changeConditionFunction={this.changeCondition} currentEditionIndex={this.state.editionIndex} currentRarityIndex={this.state.rarityIndex} currentConditionIndex={this.state.conditionIndex} />
                    </div>
                    {this.getSelectedSet()}
                </div>
            </>
        );
    }
}

export default Body;

