// Third party
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3 from 'web3';
import Web3Modal from "web3modal";

// Components
import HomeBody from './components/body/Home/Body';
import CardsBody from './components/body/Library/Body';
import CollectionBody from './components/body/Collection/Body';
import SharedCollectionBody from './components/body/SharedCollection/Body';
import FaqBody from './components/body/FAQ/Body';
import TermsBody from './components/body/Terms/Body';
import PrivacyBody from './components/body/Privacy/Body';
import SupportBody from './components/body/Support/Body';
import PaymentOngoingBody from './components/body/Payment/Ongoing/Body';
import PaymentSuccessBody from './components/body/Payment/Success/Body';
import PaymentFreeBody from './components/body/Payment/Free/Body';
import PaymentFailureBody from './components/body/Payment/Failure/Body';
import Header from './components/Header';
import Footer from './components/Footer';

// Services
import { getCardsWithMock, getCardMintPayload } from './functions/services/cards/CardService';
import './App.css';
import pawstersNftcgContract from './contracts/PawstersNFTCG.json';

const ETHEREUM_NETWORK_ID = process.env.REACT_APP_ETHEREUM_NETWORK_ID // !NOTE this field is network depedant
const ORIGINS_SALES_START = process.env.REACT_APP_ORIGINS_SALES_START // !NOTE this field is network depedant

if (
  process.env.REACT_APP_ETHEREUM_NETWORK_ID === undefined ||
  process.env.REACT_APP_ORIGINS_SALES_START === undefined ||
  process.env.REACT_APP_ETHERSCAN_BASE_URL === undefined ||
  process.env.REACT_APP_NETWORK_NAME === undefined ||
  process.env.REACT_APP_PAWSTERS_CLOUD_API_URL === undefined ||
  process.env.REACT_APP_SPA_URL === undefined ||
  process.env.REACT_APP_SPA_VERSION === undefined ||
  process.env.REACT_APP_PAWSTERS_FILES_URL === undefined ||
  process.env.REACT_APP_INFURA_ID === undefined ||
  process.env.REACT_APP_FORTMATIC_KEY === undefined ||
  process.env.REACT_APP_ENVIRONMENT === undefined
) {
  console.log(process.env.REACT_APP_ETHEREUM_NETWORK_ID)
  console.log(process.env.REACT_APP_ORIGINS_SALES_START)
  console.log(process.env.REACT_APP_ETHERSCAN_BASE_URL)
  console.log(process.env.REACT_APP_NETWORK_NAME)
  console.log(process.env.REACT_APP_PAWSTERS_CLOUD_API_URL)
  console.log(process.env.REACT_APP_SPA_URL)
  console.log(process.env.REACT_APP_PAWSTERS_FILES_URL)
  console.log(process.env.REACT_APP_SPA_VERSION)
  console.log(process.env.REACT_APP_ENVIRONMENT)
  console.log(process.env.REACT_APP_INFURA_ID)
  console.log(process.env.REACT_APP_FORTMATIC_KEY)
  console.error("MISSING REQUIRED ENVIRONMENT VARIABLES!")
  process.exit(1)
}

const MINIMUM_DELAY = 1000;
const PAWSTERS_NFTCG_CONTRACT_ADDRESS = pawstersNftcgContract.networks[ETHEREUM_NETWORK_ID].address;
const FIRST_EDITION_PERIOD = 3456000000;
const LIMITED_EDITION_PERIOD = 15552000000;

const salesPeriod = {
  originsSalesStart: Number.parseInt(ORIGINS_SALES_START),
  firstEditionPeriod: FIRST_EDITION_PERIOD,
  limitedEditionPeriod: LIMITED_EDITION_PERIOD
}

class App extends Component {
  constructor(props) {
    super(props);
    const web3Options = {
      account: null,
      web3: null,
      provider: null
    }
    this.state = {
      pawstersnftcgContract: null,
      web3Options: web3Options,
      theme: "light",
      cards: [],
      mocked: false,
      cardsLoading: true
    };
  }

  mint = async (cardIdentifier) => {
    if (this.state.mocked) {
      toast.error('Demo cards are not mintable', { theme: 'colored', isLoading: false, autoClose: 4000 });
    } else {
      const id = toast('Preparing to mint...', { isLoading: true });
      await new Promise(r => setTimeout(r, 1000));
      try {
        const account = this.state.web3Options.account;
        const mintResponse = await getCardMintPayload(account, cardIdentifier);
        toast.success('Ready to mint!', { theme: 'colored', isLoading: false, autoClose: 3000 });
        toast.update(id, { render: 'Waiting for transaction confirmation...', type: toast.TYPE.DEFAULT, theme: 'colored', isLoading: true });
        console.log("Waiting...!")
        await this.state.pawstersnftcgContract.methods
          .mint(mintResponse.tokenId, mintResponse.ownerAddress, mintResponse.signature.v, mintResponse.signature.r, mintResponse.signature.s)
          .send({ from: account });
        console.log("Finished!")
        toast.update(id, { render: 'Successfully minted card! Minted status on Pawsters.io will be updated within 15 minutes', type: toast.TYPE.SUCCESS, theme: 'colored', isLoading: false, autoClose: 10000 });
      } catch (err) {
        const error = (typeof (err) === "string") ? JSON.parse(err) : err;
        console.log(error.message)
        console.log(err.message)
        switch (error.code) {
          case 4900:
          case 4901:
            toast.update(id, { render: 'Web 3.0 provider is disconnected from the chain', type: toast.TYPE.ERROR, theme: 'colored', isLoading: false, autoClose: 2000 });
            break;

          case -32603:
          case 4001:
            toast.update(id, { render: 'Transaction rejected by user', type: toast.TYPE.ERROR, theme: 'colored', isLoading: false, autoClose: 2000 });
            break;

          case -32003:
            toast.update(id, { render: 'Transaction rejected', type: toast.TYPE.ERROR, theme: 'colored', isLoading: false, autoClose: 2000 });
            break;

          case -32005:
            toast.update(id, { render: 'Request limit exceeded', type: toast.TYPE.ERROR, theme: 'colored', isLoading: false, autoClose: 2000 });
            break;

          default:
            toast.update(id, { render: error?.message || 'Transaction failed due to an unknown error', type: toast.TYPE.ERROR, theme: 'colored', isLoading: false, autoClose: 2000 });
            break;
        }
      }
      console.log("We're past the catch clause now...")
    }
  }

  getCards = async (isSilent = false) => {
    const start = Date.now();
    let id;
    if (!isSilent) {
      id = toast('Loading cards...', { isLoading: true });
    }
    const cardList = [];
    let cardsResponse = await getCardsWithMock(this.state.web3Options.account, this.setMockedState);
    cardList.push(...cardsResponse.cards)
    this.setState({ cards: cardList, cardsLoading: false });
    while (cardsResponse.hasMoreCards) {
      const newPaginationIndex = cardsResponse.currentPaginationIndex + 1;
      cardsResponse = await getCardsWithMock(this.state.web3Options.account, this.setMockedState, newPaginationIndex);
      cardList.push(...cardsResponse.cards)
      this.setState({ cards: cardList, cardsLoading: false });
    }
    const requestDurationMillis = Date.now() - start
    if (MINIMUM_DELAY - requestDurationMillis > 0) {
      await new Promise(r => setTimeout(r, MINIMUM_DELAY - requestDurationMillis));
    }
    if (!isSilent) {
      if (this.state.mocked) {
        toast.update(id, { render: 'Failed to load cards', type: toast.TYPE.ERROR, theme: 'colored', isLoading: false, autoClose: 2000 });
      } else {
        toast.update(id, { render: 'Successfully loaded cards!', type: toast.TYPE.SUCCESS, theme: 'colored', isLoading: false, autoClose: 2000 });
      }
    }
  }

  setMockedState = (newValue) => {
    this.setState({ mocked: newValue })
  }

  connect = async () => {
    const id = toast('Connecting wallet...', { isLoading: true });
    await new Promise(r => setTimeout(r, 1000));
    try {
      const providerOptions = {
        fortmatic: {
          package: Fortmatic, // required
          options: {
            key: process.env.REACT_APP_FORTMATIC_KEY // required
          }
        },
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: process.env.REACT_APP_INFURA_ID // required
          }
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK, // required
          options: {
            infuraId: process.env.REACT_APP_INFURA_ID // required
          }
        }
      };
      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      });

      /* Ugly demo-mode */
      let web3Options
      if (window?.location?.search && window.location.search.includes("demo")) {
        web3Options = {
          web3Modal,
          account: "0xdEmo-ADdRESs",
          network: "demo",
          connected: true,
          contract: PAWSTERS_NFTCG_CONTRACT_ADDRESS
        }
        this.setState({
          web3Options: web3Options
        });
        console.log(`Logged in (demo mode) as 0xdEmo-ADdRESs`);
      } else {
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        const address = accounts[0];
        const network = await web3.eth.net.getNetworkType();
        web3Options = {
          web3Modal,
          account: address,
          web3: web3,
          network: network,
          provider: provider,
          connected: true,
          contract: PAWSTERS_NFTCG_CONTRACT_ADDRESS
        }
        const pawstersnftcgContract = new web3.eth.Contract(pawstersNftcgContract.abi, PAWSTERS_NFTCG_CONTRACT_ADDRESS)
        this.setState({
          web3Options: web3Options,
          pawstersnftcgContract: pawstersnftcgContract
        });
        console.log(`Logged in as ${this.state.web3Options.account}`);
      }

      toast.update(id, { render: "Successfully connected!", theme: 'colored', type: toast.TYPE.SUCCESS, isLoading: false, autoClose: 2500 });
      this.getCards(true);
    } catch (error) {
      console.error(error);
      toast.update(id, { render: `Error while connecting wallet`, type: toast.TYPE.ERROR, theme: 'colored', isLoading: false, autoClose: 2500 });
    }
  }

  disconnect = async () => {
    const id = toast('Disconnecting wallet...', { isLoading: true });
    await new Promise(r => setTimeout(r, 1000));
    localStorage.clear(); // Remove WalletConnect cached wallet
    const web3Options = {
      account: null,
      web3: null,
      provider: null,
      connected: false
    }
    this.setState({
      web3Options: web3Options,
      mocked: false
    });
    toast.update(id, { render: `Successfully disconnected!`, type: toast.TYPE.SUCCESS, theme: 'colored', isLoading: false, autoClose: 2500 });
  }

  setBody = () => {
    return <Switch>
      <Route path="/faq">
        <FaqBody web3Options={this.state.web3Options} />
      </Route>
      <Route path="/collection/shared">
        <SharedCollectionBody getCardsFunction={this.getCards} />
      </Route>
      <Route path="/collection">
        <CollectionBody web3Options={this.state.web3Options} getCardsFunction={this.getCards} connectFunction={this.connect} mintFunction={this.mint} cards={this.state.cards} isMocked={this.state.mocked} cardsLoading={this.state.cardsLoading} />
      </Route>
      <Route path="/library">
        <CardsBody web3Options={this.state.web3Options} />
      </Route>
      <Route path="/payment/ongoing">
        <PaymentOngoingBody />
      </Route>
      <Route path="/payment/success">
        <PaymentSuccessBody getCardsFunction={() => this.getCards(true)} />
      </Route>
      <Route path="/payment/claim/free">
        <PaymentFreeBody web3Options={this.state.web3Options} getCardsFunction={() => this.getCards(true)} cards={this.state.cards} />
      </Route>
      <Route path="/payment/failure">
        <PaymentFailureBody />
      </Route>
      <Route path="/terms">
        <TermsBody />
      </Route>
      <Route path="/privacy">
        <PrivacyBody />
      </Route>
      <Route path="/support">
        <SupportBody />
      </Route>
      <Route path="/">
        <HomeBody cards={this.state.cards} web3Options={this.state.web3Options} connectFunction={this.connect} salesPeriod={salesPeriod} />
      </Route>
    </Switch>
  }

  render() {
    return (
      <Router>
        <div className={`App background5-${this.state.theme}`}>
          <header className="header kaffeesatz">
            <Header web3Options={this.state.web3Options} connectFunction={this.connect} disconnectFunction={this.disconnect} />
          </header>

          <div className="body-class">
            <ToastContainer
              position="bottom-center"
              bodyClassName="kaffeesatz" s
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              closeOnClick
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
            />
            {/* Conditionally replace the body depending on route */}
            {this.setBody()}
          </div>

          <div className="footer background4 kaffeesatz text-center">
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
