import React from 'react';

class Body extends React.Component {

    /**
    * React hook
    */
    componentDidMount() {
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG - Support"
        } else {
            document.title = "Pawsters NFTCG - Support (dev)"
        }
    }

    render() {
        return (
            <>
                <div style={{ marginTop: '100px' }}>
                    <div className="jumbotron jumbotron-fluid background5 color1">
                        <div className="container faq-body didact-gothic-regular">
                            <h1 className="faq-title kaffeesatz">Support</h1>
                            <p>Contact us via <a href="mailto:support@pawsters.io">mail</a></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Body;

