import React from 'react';
import Modal from 'react-bootstrap/Modal'
import CopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import { getSpaUrl } from '../../../functions/services/config/ConfigService';

function ShareModal(props) {

    function copyLink() {
        /* Get the text field */
        var copyLinkInput = document.getElementById("share-collection-link");

        /* Select the text field */
        copyLinkInput.select();
        copyLinkInput.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyLinkInput.value);
        toast.success('Copied link to clipboard!', { theme: 'colored', isLoading: false, autoClose: 3000 });
    }

    return (
        <>
            <Modal
                dialogClassName="share-modal"
                show={props.show}
                onHide={props.hide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><strong className="kaffeesatz">Share collection</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-3">
                    <form>
                        <div className="form-group mt-2 mb-2">
                            <input readOnly value={`${getSpaUrl()}/collection/shared?address=${props.address}`} className="form-control input-lg" id="share-collection-link" type="text" />
                        </div>
                    </form>
                    <button type="button" onClick={() => copyLink()} className="btn btn-light">Copy &nbsp;&nbsp;<CopyIcon fontSize="small"/></button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ShareModal;
