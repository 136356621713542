import React from 'react';
import LibraryCard from './LibraryCard';

/*
"cards": [
      {
        "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
        "dna": "1111311",
        "cardIdentifier": "0xa4d90c7b6d7f48f2a2a3a2097818fd72",
        "created": "2021-07-09T11:07:54.222Z",
        "species": "Avia",
        "color": "1",
        "collectionSet": "Origins",
        "condition": "Excellent",
        "worldFirst": "Yes",
        "rarity": "Common"
      }
*/
class LibraryCardBox extends React.Component {
    render() {
        return (
            <>
                <div className="container px-0 card-container" style={{ marginTop: "25px" }}>
                    <div className='container  px-1'>
                        <div className='row' style={{ margin: "0px" }} id='cardContainer'>
                            {this.props.cards.map(card =>
                                (<LibraryCard key={card.dna} card={card} />))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LibraryCardBox;