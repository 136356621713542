import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CollectionCardModal from './CollectionCardModal';

function BlurrableInfoCard(props) {
  const [showModal, setShowModal] = useState(false);

  const getCard = () => {
    return <>
      <div className="col-6 col-sm-4 col-md-3 col-lg-2 p-1">
        <Card className='background5 shadow border-0' style={{cursor: "pointer"}} onClick={() => setShowModal(true)}>
          <Card.Title className="product-card-title kaffeesatz mb-3">{props.cardName}</Card.Title>
          <Card.Img className="product-card-image mb-1" variant="top" src={props.link} />
        </Card >
      </div>
      <CollectionCardModal
        className="card-modal"
        show={showModal}
        showButton={false}
        hide={() => setShowModal(false)}
        card={props.card} />
    </>
  }

  const getBlurredCard = () => {
    return <>
      <div className="col-6 col-sm-4 col-md-3 col-lg-2 p-1">
        <Card className='background5 shadow border-0'>
          <Card.Title className="product-card-title kaffeesatz mb-3">{props.cardName} </Card.Title>
          <div style={{ filter: "grayscale(1) blur(5px)", WebkitFilter: "grayscale(1) blur(3px)" }}>
            <Card.Img className="product-card-image mb-1" variant="top" src={props.link} />
          </div>
        </Card >
      </div>
    </>
  }

  if (props.isBlurred) {
    return getBlurredCard()
  }
  return getCard();
}

export default BlurrableInfoCard;