import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import HomeButton from '../../../shared/navigation/HomeButton';
import { toast } from 'react-toastify';
import { verifyPayment } from '../../../../functions/services/payments/PaymentService';

function Body() {
    const [paymentTitle, setPaymentTitle] = useState("Verifying payment...")
    const [paymentInformation, setPaymentInformation] = useState("Hang tight, we're verifying your payment!")
    const [cancelButtonText, setCancelButtonText] = useState("Cancel")
    const history = useHistory();
    const POLL_INITIAL_DELAY = 10_000;
    const POLL_DELAY = 4_000;
    const HOUR_IN_MILLIS = 3600_000

    // This useEffect runs on mount and changes to "history"
    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG - Payment"
        } else {
            document.title = "Pawsters NFTCG - Payment (dev)"
        }
        // Define polling function
        const verifyPaymentAndRedirect = async (pollingId, address) => {
            if (pollCounter === 0) {
                //Wait 10 seconds before polling starts
                await new Promise(r => initialDelayTimeout = setTimeout(r, POLL_INITIAL_DELAY));
            }
            if (pollCounter === (HOUR_IN_MILLIS / POLL_DELAY)) {
                // The checkout session expires one hour after checkout was created
                payment = { status: "cancelled" }
            } else {
                payment = await verifyPayment(pollingId);
            }
            if (payment) {
                switch (payment.status) {
                    case "succeeded":
                        toast.dismiss(toastId);
                        toast.success('Payment successful', { theme: 'colored', isLoading: false, autoClose: 3000 });
                        removeToast = false;
                        clearTimeout(initialDelayTimeout)
                        clearTimeout(pollTimeout)
                        history.push(`/payment/success?source=frontend&pollingId=${pollingId}&address=${address}`);
                        break;

                    case "invalid":
                        toast.dismiss(toastId);
                        toast.error('Payment invalid', { theme: 'colored', isLoading: false, autoClose: 3000 });
                        removeToast = false;
                        clearTimeout(initialDelayTimeout)
                        clearTimeout(pollTimeout)
                        history.push("/payment/failure?status=invalid");
                        break;

                    case "failed":
                        pollCounter++;
                        toast.update(toastId, { render: 'Payment failed, update payment information', type: toast.TYPE.ERROR, theme: 'colored', autoClose: 3000 });
                        removeToast = true;
                        setPaymentTitle("Payment failed")
                        setPaymentInformation("Update your payment information and try again or cancel the payment")
                        setCancelButtonText("Cancel")
                        clearTimeout(pollTimeout) // Clear old and set a new timeout, otherwise we risk double timers
                        pollTimeout = setTimeout(() => verifyPaymentAndRedirect(pollingId, address), POLL_DELAY)
                        break;

                    case "cancelled":
                        toast.dismiss(toastId);
                        toast.error('Payment cancelled', { theme: 'colored', isLoading: false, autoClose: 3000 });
                        removeToast = false;
                        clearTimeout(initialDelayTimeout)
                        clearTimeout(pollTimeout)
                        history.push("/payment/failure?status=cancelled");
                        break;

                    case "ongoing":
                        pollCounter++;
                        pollTimeout = setTimeout(() => verifyPaymentAndRedirect(pollingId, address), POLL_DELAY)
                        break;

                    default:
                        // do nothing
                        break;
                }
            }
        }

        // Define all constants and variables
        let removeToast = true;
        let pollCounter = 0;
        let payment;
        let initialDelayTimeout;
        let pollTimeout;
        const toastId = toast('Verifying payment...', { isLoading: true });
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const pollingId = params.get('pollingId');
        const address = params.get('address');
        verifyPaymentAndRedirect(pollingId, address);

        // Runs on unmount
        return () => {
            clearTimeout(initialDelayTimeout)
            clearTimeout(pollTimeout)
            if (removeToast) {
                toast.dismiss(toastId);
            }
        };
    }, [history]);



    return (
        <>
            <div style={{ marginTop: '100px', }}>
                <div className="container">
                    <h1>{paymentTitle}</h1>
                    <p className="didact-gothic-regular">{paymentInformation}</p>
                    <HomeButton title={cancelButtonText} />
                </div>
            </div>
        </>
    );

}

export default Body;

