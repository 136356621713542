import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import CardModal from './CardModal';

function CardCard(props) {
    const [showModal, setShowModal] = useState(false);

    return <>
        <div className="col-sm-6 col-md-4 col-lg-3 p-1 product-card cursor-pointer">
            <Card className='background5 shadow border-0' onClick={() => setShowModal(true)} style={{cursor: "pointer"}}>
                <Card.Title className="product-card-title kaffeesatz">{props.card.name}</Card.Title>
                <p className="product-card-id">{props.card.identifier}</p>
                <Card.Img className="product-card-image" variant="top" src={props.card.links.imageLowQuality} />
                <Card.Body className="color1 product-card-body">
                </Card.Body>
            </Card >
        </div>
        <CardModal
            className="card-modal"
            show={showModal}
            hide={() => setShowModal(false)}
            card={props.card} />
    </>
}

export default CardCard;