import React from 'react';
import CardBox from './CardBox';
import BlurrableCardBox from './BlurrableCardBox';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { getCards } from '../../../functions/services/cards/CardService';

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRadioValue: this.radios.values().next().value.value,
            currentCollectionSet: "001",
            currentCollectionRarity: "1",
            cardsLoading: true,
            cards: [],
            address: 'unknown'
        };
    }

    getCards = async (address) => {
        const cardList = [];
        let cardsResponse = await getCards(address);
        cardList.push(...cardsResponse.cards)
        this.setState({ cards: cardList, cardsLoading: false });
        while (cardsResponse.hasMoreCards) {
            const newPaginationIndex = cardsResponse.currentPaginationIndex + 1;
            cardsResponse = await getCards(address, newPaginationIndex);
            cardList.push(...cardsResponse.cards)
            this.setState({ cards: cardList });
        }
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const address = params.get('address');
        this.setState({ address });
        this.getCards(address);
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG - Shared collection"
        } else {
            document.title = "Pawsters NFTCG - Shared collection (dev)"
        }
    }

    radios = [
        { name: 'All cards', value: '1' },
        { name: 'Collections', value: '2' }
    ];

    setRadioValue = (newValue) => {
        this.setState({ currentRadioValue: newValue });
    }

    changeCollectionRarity = (selectedOptions) => {
        const newRarityIndex = selectedOptions.selectedKey[0];
        this.setState({ currentCollectionRarity: newRarityIndex });
    }

    changeCollectionSet = (selectedOptions) => {
        const newSetIndex = selectedOptions.selectedKey[0];
        this.setState({ currentCollectionSet: newSetIndex });
    }

    getCollectionDropDowns = () => {
        const set = [
            {
                "labelKey": "001",
                "value": "Origins",
                "isSelected": true
            }
        ];
        const rarity = [
            {
                "labelKey": "1",
                "value": "Common",
                "isSelected": true,
                "isDisabled": false
            },
            {
                "labelKey": "2",
                "value": "Bronze",
                "isSelected": false,
                "isDisabled": false
            },
            {
                "labelKey": "3",
                "value": "Silver",
                "isSelected": false,
                "isDisabled": false
            },
            {
                "labelKey": "4",
                "value": "Gold",
                "isSelected": false,
                "isDisabled": false
            },
            {
                "labelKey": "5",
                "value": "Diamond",
                "isSelected": false,
                "isDisabled": false
            }
        ];
        return (
            <>
                <div className="container">
                    <div className="m-2">
                        <div className="row justify-content-center">
                            <div className="col-6 col-sm-4 col-lg-3">
                                <p className="library-dropdown-text">Set</p>
                                <BootstrapSelect options={set} isMultiSelect={false} showTick={false} onChange={this.changeCollectionSet} placeholder="Set..." className="filter-dropdown" />
                            </div>
                            <div className="col-6 col-sm-4 col-lg-3">
                                <p className="library-dropdown-text">Rarity</p>
                                <BootstrapSelect options={rarity} isMultiSelect={false} showTick={false} onChange={this.changeCollectionRarity} placeholder="Rarity..." className="filter-dropdown" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    getCardCollection = () => {
        return <>
            {this.getRadioButtons()}
            {this.getCollectionDropDowns()}
            <div className="card-container">
                <BlurrableCardBox getCardsFunction={this.props.getCardsFunction} title={`Collection owned by ${this.state.address}`} ownedCards={this.state.cards} set={this.state.currentCollectionSet} rarity={this.state.currentCollectionRarity} />
            </div>
        </>;
    }

    getAllCards = () => {
        return <>
            {this.getRadioButtons()}
            <div className="card-container">
                <CardBox getCardsFunction={this.props.getCardsFunction} title={`Cards owned by ${this.state.address}`} cards={this.state.cards} cardsLoading={this.state.cardsLoading} />
            </div>
        </>;
    }

    getCollectionBody = () => {
        if (this.state.currentRadioValue === '2') {
            return this.getCardCollection();
        } else {
            return this.getAllCards();
        }
    }

    getRadioButtons = () => {
        return <>
            <ButtonGroup>
                {this.radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        size='md'
                        variant='outline-dark'
                        name="collectionRadioButtons"
                        value={radio.value}
                        checked={this.state.currentRadioValue === radio.value}
                        onChange={(e) => this.setRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    }

    render() {
        return (
            <>
                <>
                    <div style={{ marginTop: '100px' }}>
                        {this.getCollectionBody()}
                    </div>
                </>
            </>
        );
    }
}

export default Body;

