import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ConnectIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import CardIcon from '@mui/icons-material/CreditCardRounded';
import CryptoIcon from '@mui/icons-material/CurrencyBitcoinRounded';
import { getApiUrl, getImageUrl } from '../../../functions/services/config/ConfigService';
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';

function BuyPackModal(props) {

    function payCDC(history){
        const address = props.web3Options?.account || "";
        const pollingId = uuidv4() || "";
        const initiatePaymentUrl = `${getApiUrl()}/v1/payments/crypto/packs/origins?address=${address}&pollingId=${pollingId}`
        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = initiatePaymentUrl;
        form.style.display = "none";
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        if (pollingId === "" || address === "") {
            toast.error('You must connect your wallet before purchasing cards!')
        }
        history.push(`/payment/ongoing?pollingId=${pollingId}&address=${address}`)
    }

    function payStripe(history) {
        const address = props.web3Options?.account || "";
        const pollingId = uuidv4() || "";
        const initiatePaymentUrl = `${getApiUrl()}/v1/payments/packs/origins?address=${address}&pollingId=${pollingId}`
        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = initiatePaymentUrl;
        form.style.display = "none";
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        if (pollingId === "" || address === "") {
            toast.error('You must connect your wallet before purchasing cards!')
        }
        history.push(`/payment/ongoing?pollingId=${pollingId}&address=${address}`)
    }

    const getButtons = () => {
        if (props.web3Options?.account) {
            return <>
                <div className='d-flex justify-content-start'>
                    <p className='mb-2 mt-0 pt-0 fs-6'><strong>Payment options:</strong></p>
                </div>
                <div>
                    <CardPayButton className='me-1' />
                    <CryptoPayButton />
                </div>
            </>
        }
        return <button id="bannerConnectButton" onClick={props.connectFunction} type="button" className="btn btn-light kaffeesatz"><ConnectIcon className='align-top' sx={{ fontSize: "24px" }} /><span className='align-top fs-7 ps-1 kaffeesatz'> Connect wallet to pay</span></button>
    }

    const CardPayButton = withRouter(({ history }) => (
        <button disabled className="btn mx-1 background2 font5 text-small" type="submit" onClick={() => payStripe(history)}><CardIcon className='align-top me-1' sx={{ fontSize: "20px" }} />Credit/Debit Card</button>
    ))

    const CryptoPayButton = withRouter(({ history }) => (
        <button className="btn mx-1 background2 font5 text-small" type="submit" onClick={() => payCDC(history)}><CryptoIcon className='align-top me-1' sx={{ fontSize: "20px" }} />Crypto.com Pay</button>
    ))


    return (
        <>
            <Modal
                dialogClassName="buy-modal"
                show={props.show}
                onHide={props.hide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body className="m-1">
                    <section>
                        <div className="product m-1">
                            <div className="d-block d-sm-none">
                                <img className="mt-0 mb-4" style={{ maxWidth: "100px" }} src={`${getImageUrl()}/packs/packs_1-lg.png`} alt="One Pawsters NFTCG Origins card pack" />
                            </div>
                            <div className="d-none d-sm-block">
                                <img className="mt-0 mb-4" src={`${getImageUrl()}/packs/packs_1-lg.png`} style={{ maxWidth: "150px" }}  alt="One Pawsters NFTCG Origins card pack" />
                            </div>
                            <div className="description rounded background4 m-0 p-3">
                                <p className='fs-5 p-0 m-0'><strong>1x Origins Packs</strong></p>
                                <p className='fs-3 p-0 m-1'><strong>3.99$</strong></p>
                                <p className="px-4">Origins is the first set of Pawsters NFTCG cards. Each pack contains four random cards, with each card having the same chance of being rare.</p>
                                {getButtons()}
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BuyPackModal;
