import React from 'react';
import BlurrableInfoCard from './BlurrableInfoCard';
import RefreshIcon from '@mui/icons-material/Refresh';

import { getAllOriginsCards } from '../../../functions/services/cards/CardService';

function isEditionBetter(currentCard, newCard) {
  return newCard.dna.substring(8, 9) > currentCard.dna.substring(8, 9);
}

function isEditionSameButConditionBetter(currentCard, newCard) {
  return (newCard.dna.substring(8, 9) === currentCard.dna.substring(8, 9)) && (newCard.dna.substring(10, 11) > currentCard.dna.substring(10, 11));
}

function isCurrentRarityAndSet(newCard, rarity, set) {
  return newCard.dna.substring(4, 7) === set && newCard.dna.substring(12, 13) === rarity;
}

function getBestCardsMap(cardsArray = [], rarity, set) {
  const cardMap = new Map();
  cardsArray.forEach((newCard) => {
    if (isCurrentRarityAndSet(newCard, rarity, set)) {
      const currentCard = cardMap.get(newCard.cardNumber);
      if (currentCard) {
        if (isEditionBetter(currentCard, newCard) || isEditionSameButConditionBetter(currentCard, newCard)) {
          cardMap.set(newCard.cardNumber, newCard);
        }
      } else {
        cardMap.set(newCard.cardNumber, newCard);
      }
    }
  });
  return cardMap;
}

function getAllCardsFromSet(set, rarity) {
  switch (set) {
    //Origins, default is "Gem" condition and "Limited Edition" edition
    case "001":
      return getAllOriginsCards(1, rarity, 4).cards

    default:
      return [];
  }
}

function getCardsInSet(set) {
  switch (set) {
    case "001":
      return 36;

    default:
      return 0;
  }
}

function getCardCards(rarity, set, bestOwnedCards) {
  const allCardsFromSet = getAllCardsFromSet(set, rarity);
  return allCardsFromSet.map(card => {
    const ownedCard = bestOwnedCards.get(card.cardNumber);
    if (ownedCard) {
      return (<BlurrableInfoCard key={ownedCard.name} cardName={ownedCard.name} link={ownedCard.links.imageLowQuality} card={ownedCard} isBlurred={false} />);
    }
    return (<BlurrableInfoCard key={card.name} cardName={card.name} link={card.links.imageLowQuality} isBlurred={true} />);
  });
}

/*
"cards": [
      {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xb1266741fc23454ebad7a6a998e64975",
                "created": "2022-02-11T06:56:43.436Z",
                "dna": "002_001_3_1_1",
                "name": "Drakoon 2/36",
                "cardNumber": "002",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Drakoon",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/002_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/002_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/002_001_3_1_1.png"
                }
            }
*/
class BlurrableCardBox extends React.Component {
  render() {
    const bestOwnedCards = getBestCardsMap(this.props.ownedCards, this.props.rarity, this.props.set);
    return (
      <>
        <div className="container">
          <div className='m-2 mt-5'>
            <div className="row justify-content-center">
              <h4>{this.props.title}</h4>
              <hr className="background2 col-6" />
              <p className="collection-collected-x-text">Collected {bestOwnedCards.size} of {getCardsInSet(this.props.set)}</p>
            </div>
            <div className="row m-0">
              <div className="card-box-actions mb-4 p-0">
                <div>
                  <button className="btn p-0" onClick={() => this.props.getCardsFunction(false)}>
                    <RefreshIcon fontSize="medium" />
                  </button>
                </div>
              </div>
            </div>
            <div className='row'>
              {getCardCards(this.props.rarity, this.props.set, bestOwnedCards)}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default BlurrableCardBox;