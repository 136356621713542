import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ConnectIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import { getImageUrl } from '../../../functions/services/config/ConfigService';
import { withRouter } from 'react-router-dom'

function ClaimPackModal(props) {

    async function pay(history) {
        history.push(`/payment/claim/free`)
    }

    const getButton = () => {
        if (props.web3Options?.account) {
            if (props.cards.length <= 0) {
                return <ClaimButton />
            }
            const BUTTON_CLASS = 'btn background2 font5';
            const BUTTON_TYPE = 'button';
            return <button type={BUTTON_TYPE} disabled className={BUTTON_CLASS}>Not eligible</button>

        }
        return <button id="bannerConnectButton" onClick={props.connectFunction} type="button" className="btn btn-light kaffeesatz"><ConnectIcon className='align-top' sx={{ fontSize: "24px" }} /><span className='align-top fs-7 ps-1 kaffeesatz'> Connect wallet to claim</span></button>
    }

    const ClaimButton = withRouter(({ history }) => (
        <button className="btn background2 font5 fs-7" type="submit" onClick={() => pay(history)}>Claim free pack!</button>
    ))


    return (
        <>
            <Modal
                dialogClassName="claim-modal"
                show={props.show}
                onHide={props.hide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton />
                <Modal.Body className="m-1">
                    <section>
                        <div className="product m-1">
                            <div className="d-block d-sm-none">
                                <img className="mt-4 mb-5" style={{ maxWidth: "150px" }} src={`${getImageUrl()}/packs/packs_1-lg.png`} alt="One Pawsters NFTCG Origins card pack" />
                            </div>
                            <div className="d-none d-sm-block">
                                <img className="mt-4 mb-5" src={`${getImageUrl()}/packs/packs_1-lg.png`} alt="One Pawsters NFTCG Origins card pack" />
                            </div>
                            <div className="description rounded background4 m-0 p-3">
                                <h3><strong>1x Origins Packs</strong></h3>
                                <p className='fs-6'><strong>FREE</strong></p>
                                <p className="px-4">Origins is the first set of Pawsters NFTCG cards. Each pack contains four random cards, with each card having the same chance of being rare.</p>
                                {getButton()}
                                <p className='small text-muted mt-2'>All new collectors with no cards in their collection are eligible for the one-time, free card pack.</p>
                            </div>
                        </div>

                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ClaimPackModal;
