import React from 'react';
import HomeButton from '../../../shared/navigation/HomeButton';
import WindowCloseButton from '../../../shared/navigation/WindowCloseButton';

class Body extends React.Component {

    /**
    * React hook
    */
    componentDidMount() {
        if (process.env.REACT_APP_ENVIRONMENT === "Prod") {
            document.title = "Pawsters NFTCG - Payment failed"
        } else {
            document.title = "Pawsters NFTCG - Payment failed (dev)"
        }
    }

    getFailureReasonBody = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const source = params.get('source');
        if (source === "backend") {
            window.close();
        }
        const status = params.get('status');
        switch (status) {
            case "expired":
                return <>
                    <h1>Payment expired</h1>
                    <p className="didact-gothic-regular">Your payment session expired</p>
                    <HomeButton title="Go back" />
                </>

            case "cancelled":
                return <>
                    <h1>Payment cancelled</h1>
                    <p className="didact-gothic-regular">Your payment was cancelled</p>
                    <HomeButton title="Go back" />
                </>

            case "bad-request":
                return <>
                    <h1>Payment invalid</h1>
                    <p className="didact-gothic-regular">Your payment could not be processed due to missing information</p>
                    <WindowCloseButton title="Go back" />
                </>

            case "invalid":
                return <>
                    <h1>Payment invalid</h1>
                    <p className="didact-gothic-regular">We could not find any active payments, please try again</p>
                    <HomeButton title="Go back" />
                </>

            case "not-for-sale":
                return <>
                    <h1>Not for sale</h1>
                    <p className="didact-gothic-regular">This product is not for sale, yet...!</p>
                    <HomeButton title="Go back" />
                </>

            case "failed":
            default:
                return <>
                    <h1>Payment failed</h1>
                    <p className="didact-gothic-regular">For some reason your payment could not be processed, try again with another payment option</p>
                    <HomeButton title="Go back" />
                </>
        }
    }

    render() {
        return (
            <>
                <div style={{ marginTop: '100px' }}>
                    <div className="container">
                        {this.getFailureReasonBody()}
                    </div>
                </div>
            </>
        );
    }
}

export default Body;

