function cards() {
    return {
        "cards": [
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xd11726a7089a434584d6d2b8c1412d61",
                "created": "2022-02-11T06:56:40.296Z",
                "dna": "021_001_3_3_1",
                "name": "Turkot 21/36",
                "cardNumber": "021",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Turkot",
                "condition": "Excellent",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/021_001_3_3_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/021_001_3_3_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/021_001_3_3_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xedba9ab49891478fbc59e9f7b685fdcc",
                "created": "2022-02-11T06:56:40.303Z",
                "dna": "023_001_3_1_1",
                "name": "Jirabi 23/36",
                "cardNumber": "023",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Jirabi",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/023_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/023_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/023_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xd901cd3169074cf9aae626b6692f3478",
                "created": "2022-02-11T06:56:40.306Z",
                "dna": "018_001_3_1_1",
                "name": "Pronkey 18/36",
                "cardNumber": "018",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Pronkey",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/018_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/018_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/018_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xfc1635ebde1b4fd7bc0cbd273a779e65",
                "created": "2022-02-11T06:56:40.309Z",
                "dna": "007_001_3_1_1",
                "name": "Peci 7/36",
                "cardNumber": "007",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Peci",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/007_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/007_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/007_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xcfc37d2e8d164ffcbc9bc2f976716bbe",
                "created": "2022-02-11T06:56:40.311Z",
                "dna": "016_001_3_4_2",
                "name": "Pronkey 16/36",
                "cardNumber": "016",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Pronkey",
                "condition": "Gem",
                "rarity": "Bronze",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/016_001_3_4_2.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/016_001_3_4_2.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/016_001_3_4_2.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xa63f781419c94bb4bb3a7fb1d68d35e9",
                "created": "2022-02-11T06:56:41.305Z",
                "dna": "034_001_3_3_1",
                "name": "Fossitar 34/36",
                "cardNumber": "034",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Fossitar",
                "condition": "Excellent",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/034_001_3_3_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/034_001_3_3_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/034_001_3_3_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xb4c72da10d98445a9b4c7d04ab8fb1a9",
                "created": "2022-02-11T06:56:41.309Z",
                "dna": "018_001_2_1_1",
                "name": "Pronkey 18/36",
                "cardNumber": "018",
                "collectionSet": "Origins",
                "edition": "First",
                "species": "Pronkey",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/018_001_2_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/018_001_2_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/018_001_2_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x54218f5aca154bcba96d1a14d65fa1cb",
                "created": "2022-02-11T06:56:41.313Z",
                "dna": "030_001_3_2_1",
                "name": "Splisho 30/36",
                "cardNumber": "030",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Splisho",
                "condition": "Good",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/030_001_3_2_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/030_001_3_2_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/030_001_3_2_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xca360a2d9f2344418c20304e2f5358dd",
                "created": "2022-02-11T06:56:41.317Z",
                "dna": "013_001_3_2_1",
                "name": "Caveer 13/36",
                "cardNumber": "013",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Caveer",
                "condition": "Good",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/013_001_3_2_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/013_001_3_2_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/013_001_3_2_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x553a7864f13e49819bc06c4c9b3f2cac",
                "created": "2022-02-11T06:56:41.320Z",
                "dna": "021_001_3_1_1",
                "name": "Turkot 21/36",
                "cardNumber": "021",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Turkot",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/021_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/021_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/021_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xc7aef009e67d4a3ba9f4b63fab23f4a1",
                "created": "2022-02-11T06:56:42.047Z",
                "dna": "023_001_3_2_1",
                "name": "Jirabi 23/36",
                "cardNumber": "023",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Jirabi",
                "condition": "Good",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/023_001_3_2_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/023_001_3_2_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/023_001_3_2_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x15bca78dcc454436ba0779c39b1b1ea9",
                "created": "2022-02-11T06:56:42.049Z",
                "dna": "019_001_3_1_1",
                "name": "Turkot 19/36",
                "cardNumber": "019",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Turkot",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/019_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/019_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/019_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xaddc2d6e3f3d410bab70f28472c629cb",
                "created": "2022-02-11T06:56:42.051Z",
                "dna": "001_001_3_1_1",
                "name": "Drakoon 1/36",
                "cardNumber": "001",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Drakoon",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/001_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/001_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/001_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xee7de534f6f449369b5cb01fc28215f8",
                "created": "2022-02-11T06:56:42.052Z",
                "dna": "032_001_3_1_1",
                "name": "Avia 32/36",
                "cardNumber": "032",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Avia",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/032_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/032_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/032_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x25d78564319f4a10aaec305f65b36c84",
                "created": "2022-02-11T06:56:42.053Z",
                "dna": "007_001_3_3_1",
                "name": "Peci 7/36",
                "cardNumber": "007",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Peci",
                "condition": "Excellent",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/007_001_3_3_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/007_001_3_3_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/007_001_3_3_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x13f18f33a3d34a629bc5cbef9f78975b",
                "created": "2022-02-11T06:56:42.726Z",
                "dna": "031_001_3_1_1",
                "name": "Avia 31/36",
                "cardNumber": "031",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Avia",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/031_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/031_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/031_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xa11a1ab1ce9743fdb4b95fa1159d727b",
                "created": "2022-02-11T06:56:42.728Z",
                "dna": "001_001_3_2_2",
                "name": "Drakoon 1/36",
                "cardNumber": "001",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Drakoon",
                "condition": "Good",
                "rarity": "Bronze",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/001_001_3_2_2.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/001_001_3_2_2.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/001_001_3_2_2.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x8b29b2ad36a14f7998c2c5fbed2d048a",
                "created": "2022-02-11T06:56:42.729Z",
                "dna": "002_001_3_4_1",
                "name": "Drakoon 2/36",
                "cardNumber": "002",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Drakoon",
                "condition": "Gem",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/002_001_3_4_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/002_001_3_4_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/002_001_3_4_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x303b2ad910c244e696ebdd78bd4e6515",
                "created": "2022-02-11T06:56:42.731Z",
                "dna": "014_001_3_3_2",
                "name": "Caveer 14/36",
                "cardNumber": "014",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Caveer",
                "condition": "Excellent",
                "rarity": "Bronze",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/014_001_3_3_2.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/014_001_3_3_2.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/014_001_3_3_2.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x2e0c7b4418214d5c8f2e869881f15c27",
                "created": "2022-02-11T06:56:42.732Z",
                "dna": "033_001_3_3_1",
                "name": "Avia 33/36",
                "cardNumber": "033",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Avia",
                "condition": "Excellent",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/033_001_3_3_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/033_001_3_3_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/033_001_3_3_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x578d38b58ed049d199fc94e0ade201ee",
                "created": "2022-02-11T06:56:43.427Z",
                "dna": "025_001_3_1_1",
                "name": "Relatyl 25/36",
                "cardNumber": "025",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Relatyl",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/025_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/025_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/025_001_3_1_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x8ff587b010f042f6b01923e7c3ade476",
                "created": "2022-02-11T06:56:43.428Z",
                "dna": "021_001_3_2_1",
                "name": "Turkot 21/36",
                "cardNumber": "021",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Turkot",
                "condition": "Good",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/021_001_3_2_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/021_001_3_2_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/021_001_3_2_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0x9f8a1998264844e9a0b8f918daae4df1",
                "created": "2022-02-11T06:56:43.429Z",
                "dna": "030_001_2_2_1",
                "name": "Splisho 30/36",
                "cardNumber": "030",
                "collectionSet": "Origins",
                "edition": "First",
                "species": "Splisho",
                "condition": "Good",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/030_001_2_2_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/030_001_2_2_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/030_001_2_2_1.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xa6b91595617848df8ba69228c14d19c2",
                "created": "2022-02-11T06:56:43.431Z",
                "dna": "008_001_3_4_2",
                "name": "Peci 8/36",
                "cardNumber": "008",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Peci",
                "condition": "Gem",
                "rarity": "Bronze",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/008_001_3_4_2.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/008_001_3_4_2.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/008_001_3_4_2.png"
                }
            },
            {
                "ownerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "originalOwnerAddress": "0xcf2Df0341e6835393639304c77950c596F82c5DE",
                "minted": false,
                "identifier": "0xb1266741fc23454ebad7a6a998e64975",
                "created": "2022-02-11T06:56:43.436Z",
                "dna": "002_001_3_1_1",
                "name": "Drakoon 2/36",
                "cardNumber": "002",
                "collectionSet": "Origins",
                "edition": "World First",
                "species": "Drakoon",
                "condition": "Damaged",
                "rarity": "Common",
                "links": {
                    "imageLowQuality": "https://files.pawsters.io/v2/lq/002_001_3_1_1.png",
                    "imageStandardQuality": "https://files.pawsters.io/v2/sq/002_001_3_1_1.png",
                    "imageHighQuality": "https://files.pawsters.io/v2/hq/002_001_3_1_1.png"
                }
            }
        ]
    };
}

export {
    cards
}