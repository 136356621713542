import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import HomeButton from '../../../shared/navigation/HomeButton';
import CardBox from './CardBox';
import { getApiUrl } from '../../../../functions/services/config/ConfigService';


class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCardsReady: false,
            allCards: this.props.cards,
            newCards: []
        };
    }

    claim = async () => {
        const address = this.props.web3Options?.account || "";
        const headers = { 'OwnerAddress': address }
        const claimUrl = `${getApiUrl()}/v1/cards/claim`
        if (address === "") {
            toast.error('You must connect your wallet before claiming your free pack!')
            return
        }
        try {
            const response = await fetch(claimUrl, {
                method: 'POST',
                headers
            })
            if (response.status === 200) {
                const json = await response.json()
                console.log(json)
                return json.cards
            } else {
                toast.error('The free pack has already been claimed, or your account is ineligible')
                return undefined
            }
        } catch (err) {
            console.log(err)
            toast.error('Unknown error... Send an email to support@pawsters.io with error details')
            return undefined
        }
    }

    componentDidMount() {
        this.claim().then((cards) => {
            if (cards !== undefined) {
                document.title = "Pawsters NFTCG - Claim sucessful"
                this.props.getCardsFunction()
            } else {
                document.title = "Pawsters NFTCG - Claim failed"
            }
            this.setState({ newCards: cards || [], isCardsReady: true });
        })
        .catch((error) => {
            console.log(error)
            this.setState({ isCardsReady: true });
        })
    }

    componentWillUnmount() {
        this.loadInterval && clearInterval(this.loadInterval);
        this.loadInterval = false;
    }

    showNewCards = () => {
        return <>
            <CardBox cards={this.state.newCards} />
        </>
    }

    waitForCards = () => {
        return <>
            <div className='container d-flex justify-content-center'>
                <div className="row">
                    <div className="col-12">
                        <p>Loading...</p>
                    </div>
                    <div className="col-12">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                </div>
            </div>
        </>
    }

    getHeaderText = () => {
        if (this.state.isCardsReady){
            if (this.state.newCards.length > 0){
                return "Success"
            } 
            return "Claim failed"
        }
        return "Claiming..."
    }

    getBodyText = () => {
        if (this.state.isCardsReady){
            if (this.state.newCards.length > 0){
                return "The cards below have been added to your collection"
            } 
            return "No cards were added to your collection"
        }
        return ""
    }

    render() {
        return (
            <>
                <div style={{ marginTop: '100px' }}>
                    <div className="container">
                        <h1>{this.getHeaderText()}</h1>
                        <p>{this.getBodyText()}</p>
                        <HomeButton title="Go back" />
                        {(this.state.isCardsReady) ? this.showNewCards() : this.waitForCards()}
                    </div>
                </div>
            </>
        );
    }
}

export default Body;

