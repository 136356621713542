import React from 'react';

class CountdownTimer extends React.Component {

    zeroPad = (number) => {
        if (number < 10) {
            return `0${number}`;
        }
        return String(number);
    }

    getTimeDigits = ({ endTimeEpoch, currentEpoch }) => {
        const difference = Math.floor((endTimeEpoch - currentEpoch) / 1000);
        if (difference < 0) {
            return {
                days: "--",
                hours: "--",
                minutes: "--",
                seconds: "--"
            };
        }
        const days = Math.floor(difference / 86400);
        const hoursLeft = Math.floor(difference - (days * 86400));
        const hours = Math.floor(hoursLeft / 3600);
        const minutesLeft = Math.floor(hoursLeft - (hours * 3600));
        const minutes = Math.floor(minutesLeft / 60);
        const seconds = difference % 60;
        return {
            days: this.zeroPad(days),
            hours: this.zeroPad(hours),
            minutes: this.zeroPad(minutes),
            seconds: this.zeroPad(seconds)
        };
    }

    numberCard = ({ number, text }) => {
        return <>
            <div className="m-0 p-0" style={{ width: '58px' }}>
                <div className='background4 border-0'>
                    <p className="font3 countdown-number mb-0 mx-0">{number}</p>
                    <p className="font1 countdown-text mb-0">{text}</p>
                </div>
            </div>
        </>
    }

    getUntilText = ({ endTimeEpoch, showUntilText }) => {
        const endDate = new Date(endTimeEpoch);
        const localEndDateTime = endDate.toLocaleDateString();
        if (showUntilText) {
            return <>
                <p>Available until {localEndDateTime}</p>
            </>
        }
        return <></>
    }

    getCountdown = ({ text, endTimeEpoch, showUntilText, currentEpoch, subText }) => {
        const timeDigits = this.getTimeDigits({ endTimeEpoch, currentEpoch });
        return <>
            <div className='col-12 mb-2'>
                <p className='countdown-title m-0 px-1'><b>{text}</b></p>
                {subText}
                {this.getUntilText({ endTimeEpoch, showUntilText })}
            </div>
            <div className='col-12 center'>
                <div className='countdown-box'>
                    {this.numberCard({ number: timeDigits.days, text: 'days' })}
                    {this.numberCard({ number: timeDigits.hours, text: 'hours' })}
                    {this.numberCard({ number: timeDigits.minutes, text: 'minutes' })}
                    {this.numberCard({ number: timeDigits.seconds, text: 'seconds' })}
                </div>
            </div>
        </>
    }

    render = () => {
        return (
            <>
                <div>
                    {this.getCountdown({ text: this.props.text, endTimeEpoch: this.props.endTimeEpoch, showUntilText: this.props.showUntilText, currentEpoch: this.props.currentEpoch, subText: this.props.subText })}
                </div>
            </>
        );
    }
}

export default CountdownTimer;