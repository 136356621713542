let filters = {
  "ItemRarity": [],
  "ItemCondition": [],
  "ItemSet": [],
  "ItemMinted": []
}

function searchKeyIsAMatch(key, card) {
  const values = Object.values(card);
  return values.filter(value => `${value}`.toLowerCase().includes(key.toLowerCase())).length > 0;
}

function cardMatchesSearch(searchQuery, cardInfo) {
  const searchKeys = searchQuery.split(' ');

  for (let key of searchKeys) {
    if (!searchKeyIsAMatch(key, cardInfo)) {
      return false;
    }
  }
  return true;
}

function resolveMintedValue(mintedBool) {
  if (!!mintedBool) {
    return "Minted";
  }
  return "Not minted";
}

function cardMatchesFilter(cardAttributes) {
  if (filters['ItemRarity'].length > 0 && !filters['ItemRarity'].includes(cardAttributes.rarity)) {
    return false;
  }
  if (filters['ItemCondition'].length > 0 && !filters['ItemCondition'].includes(cardAttributes.condition)) {
    return false;
  }
  if (filters['ItemSet'].length > 0 && !filters['ItemSet'].includes(cardAttributes.collectionSet)) {
    return false;
  }
  if (filters['ItemMinted'].length > 0 && !filters['ItemMinted'].includes(resolveMintedValue(cardAttributes.minted))) {
    return false;
  }
  return true;
}

export function matchesSearch({ newFilters, card }) {
  if (newFilters) {
    filters = newFilters;
  }
  const searchQuery = document.getElementById('searchBoxInput').value;
  return cardMatchesSearch(searchQuery, card) && cardMatchesFilter(card);
}