import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <>
                <div>
                    <p className="color2" style={{ padding: '20px' }}>Copyright © {new Date(Date.now()).getFullYear()} <a href="https://nilsonium.com">Nilsonium</a>, All Rights Reserved</p>
                </div>
            </>
        );
    }
}

export default Footer;
