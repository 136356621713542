import React from 'react';
import Modal from 'react-bootstrap/Modal'

function leftRightText(leftText, rightText) {
    return <div>
        <div className="d-flex justify-content-between">
            <div className="font-weight-bold">
                <strong>{leftText}</strong>
            </div>
            <div>
                {rightText}
            </div>
        </div>
    </div>
}

function CollectionCardModal(props) {

    return (
        <>
            <Modal
                dialogClassName="card-modal"
                show={props.show}
                onHide={props.hide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title><strong className="kaffeesatz">{props.card.name}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-block d-sm-none">
                        <img src={props.card.links.imageStandardQuality} alt="Depicts the selected card in better quality" style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} />
                    </div>
                    <div className="d-none d-sm-block">
                        <img src={props.card.links.imageStandardQuality} alt="Depicts the selected card in better quality" style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} />
                    </div>

                    <div className="modal-text">
                        {leftRightText("Rarity:", props.card.rarity)}
                        {leftRightText("Condition:", props.card.condition)}
                        {leftRightText("Edition:", props.card.edition)}
                        {leftRightText("Minted:", (props.card.minted) ? "Yes" : "No")}
                        {leftRightText("Species:", props.card.species)}
                        <div className="card-modal-detailed-information">
                            <br />
                            {leftRightText("Identifier:", props.card.identifier)}
                            {leftRightText("Created:", props.card.created)}
                            {leftRightText("Current owner:", props.card.ownerAddress)}
                            {leftRightText("Original owner:", props.card.originalOwnerAddress)}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CollectionCardModal;
