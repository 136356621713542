import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import LibraryCardModal from './LibraryCardModal';

function LibraryCard(props) {
    const [showModal, setShowModal] = useState(false);

    return <>
        <div className="col-sm-6 col-md-4 col-lg-3 p-1 product-card cursor-pointer">
            <Card className='background5 shadow border-0' onClick={() => setShowModal(true)} style={{cursor: "pointer"}}>
                <Card.Title className="product-card-title kaffeesatz">{props.card.name}</Card.Title>
                <Card.Img className="product-card-image" variant="top" src={props.card.links.imageLowQuality} />
                <Card.Body className="color1 product-card-body">
                </Card.Body>
            </Card >
        </div>
        <LibraryCardModal
            className="card-modal"
            show={showModal}
            showButton={false}
            hide={() => setShowModal(false)}
            card={props.card} />
    </>
}

export default LibraryCard;