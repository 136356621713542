
import React from 'react';
import BootstrapSelect from 'react-bootstrap-select-dropdown';

class LibraryDropdowns extends React.Component {

    render() {
        const rarities = [
            {
                "labelKey": "1",
                "value": "Common",
                "isSelected": this.props.currentRarityIndex === "1"
            },
            {
                "labelKey": "2",
                "value": "Bronze",
                "isSelected": this.props.currentRarityIndex === "2"
            },
            {
                "labelKey": "3",
                "value": "Silver",
                "isSelected": this.props.currentRarityIndex === "3"
            },
            {
                "labelKey": "4",
                "value": "Gold",
                "isSelected": this.props.currentRarityIndex === "4"
            },
            {
                "labelKey": "5",
                "value": "Diamond",
                "isSelected": this.props.currentRarityIndex === "5"
            }
        ];

        const conditions = [
            {
                "labelKey": "1",
                "value": "Damaged",
                "isSelected": this.props.currentConditionIndex === "1"
            },
            {
                "labelKey": "2",
                "value": "Good",
                "isSelected": this.props.currentConditionIndex === "2"
            },
            {
                "labelKey": "3",
                "value": "Excellent",
                "isSelected": this.props.currentConditionIndex === "3"
            },
            {
                "labelKey": "4",
                "value": "Gem",
                "isSelected": this.props.currentConditionIndex === "4"
            }
        ]

        const editions = [
            {
                "labelKey": "1",
                "value": "Limited",
                "isSelected": this.props.currentEditionIndex === "1"
            },
            {
                "labelKey": "2",
                "value": "First",
                "isSelected": this.props.currentEditionIndex === "2"
            },
            {
                "labelKey": "3",
                "value": "World First",
                "isSelected": this.props.currentEditionIndex === "3"
            }
        ]

        return (
            <>
                <div className="library-dropdowns">
                    <div className="row justify-content-center">
                        <div className="col-6 col-sm-4 col-lg-3">
                            <p className="library-dropdown-text">Edition</p>
                            <BootstrapSelect options={editions} isMultiSelect={false} showTick={false} onChange={this.props.changeEditionFunction} className="filter-dropdown" />
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3">
                            <p className="library-dropdown-text">Rarity</p>
                            <BootstrapSelect options={rarities} isMultiSelect={false} showTick={false} onChange={this.props.changeRarityFunction} className="filter-dropdown" />
                        </div>
                        <div className="col-6 col-sm-4 col-lg-3">
                            <p className="library-dropdown-text">Condition</p>
                            <BootstrapSelect options={conditions} isMultiSelect={false} showTick={false} onChange={this.props.changeConditionFunction} className="filter-dropdown" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LibraryDropdowns;